import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import { TelephoneForward, Envelope } from "react-bootstrap-icons";

import agentYasotha from "../assets/img/agent/agent_yasotha.webp";
import agentRajini from "../assets/img/agent/agent_rajini.webp";
import agentRathika from "../assets/img/agent/agent_rathika_senthilnathan.webp";
import agentRamesh from "../assets/img/agent/agent_ramesh_kulasekaran.webp";
import agentGowtham from "../assets/img/agent/agent_gowtham.webp";
import agentSuganthini from "../assets/img/agent/agent_suganthini.webp";
import agentSenthil from "../assets/img/agent/agent_senthil.webp";
import agentJeevitha from "../assets/img/agent/agent_Jeevitha.webp";
import agentMeera from "../assets/img/agent/agent_meera.webp";
import agentViveka from "../assets/img/agent/agent_viveka.webp";
import './../assets/css/agent.css'
import { Link } from "react-router-dom";

const TITLE = process.env.REACT_APP_SITE_NAME+' - Meet the team'
const OurTeam = () => {
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <HeaderComponent />           

            <section className="bg-dark py-5 d-none d-md-block">
                <div className="py-2 mb-1"></div>
            </section> 
            
            {/* <div className="menubackdrop"></div> */}           
            
            <section className="pt-5 agent_details bg-light">
                <Container className="pt-4">
                    <div className="wrapper pb-4">
                        <div className="d-flex justify-content-center pb-5 mb-5">
                            <h1 className="text-center text-uppercase px-4 py-2 vm_font_bold border px-5 py-3 mb-4 border-2 shadow-lg bg-white">
                            Our Team
                            </h1>
                        </div>
                        <Row className="d-flex justify-content-center">
                            <Col md={3}>
                            
                            <div className="item_wrapper">
                                <div className="item">
                                <div className="circle">
                                    <div
                                    className="agent_photo vm_bg_cover"
                                    style={{ background: `url(${agentSuganthini})` }}
                                    ></div>
                                </div>
                                <h5 className="text-center lh-base title">
                                    suganthini Senthinathan
                                </h5>
                                <p className="text-center">
                                    Broker of Record
                                </p>
                                <h5 className="text-center lh-base tel">
                                    <TelephoneForward color="black" className="me-2" />
                                    416 880-3200
                                </h5>
                                {/* <h5 className="text-center lh-base">
                                    <Envelope color="black" className="me-2" />
                                    sugi.senthinathan@gmail.com
                                </h5> */}
                                </div>
                            </div>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">                            
                            <Col md={4}>
                            <div className="item_wrapper">
                                <div className="item">
                                <div className="circle">
                                    <div
                                    className="agent_photo vm_bg_cover"
                                    style={{ background: `url(${agentSenthil})` }}
                                    ></div>
                                </div>
                                <h5 className="text-center lh-base title">
                                    Senthil Ramaswamy
                                </h5>
                                <p className="text-center">
                                Sales Representative 
                                </p>
                                <h5 className="text-center lh-base tel">
                                    <TelephoneForward color="black" className="me-2" />
                                    647 881-0455
                                </h5>
                                {/* <h5 className="text-center lh-base">
                                    <Envelope color="black" className="me-2" />
                                    senthil_ramaswamy@outlook.com
                                </h5> */}
                                </div>
                            </div>
                            
                            </Col>
                           
                            <Col md={4}>
                            <div className="item_wrapper">
                                <div className="item">
                                <div className="circle">
                                    <div
                                    className="agent_photo vm_bg_cover"
                                    style={{ background: `url(${agentRamesh})` }}
                                    ></div>
                                </div>
                                <h5 className="text-center lh-base title">
                                    Ramesan Kulasegarem
                                </h5>
                                <p className="text-center">
                                Sales Representative 
                                </p>
                                <h5 className="text-center lh-base tel">
                                    <TelephoneForward color="black" className="me-2" />
                                    647 668-0486
                                </h5>
                                {/* <h5 className="text-center lh-base">
                                    <Envelope color="black" className="me-2" />
                                    ramesankula@gmail.com
                                </h5> */}
                                </div>
                            </div>
                            </Col>
                            <Col md={4}>
                            <div className="item_wrapper">
                                <div className="item">
                                <div className="circle">
                                    <div
                                    className="agent_photo vm_bg_cover"
                                    style={{ background: `url(${agentYasotha})` }}
                                    ></div>
                                </div>
                                <h5 className="text-center lh-base title">
                                    Yasotha Sinnathurai
                                </h5>
                                <p className="text-center">
                                Sales Representative 
                                </p>
                                <h5 className="text-center lh-base tel">
                                    <TelephoneForward color="black" className="me-2" />
                                    647 300-2524
                                </h5>
                                {/* <h5 className="text-center lh-base">
                                    <Envelope color="black" className="me-2" />
                                    yasosinna@yahoo.com
                                </h5> */}
                                </div>
                            </div>
                            </Col>                           
                            <Col md={4}>
                            <div className="item_wrapper">
                                <div className="item">
                                <div className="circle">
                                    <div
                                    className="agent_photo vm_bg_cover"
                                    style={{ background: `url(${agentRajini})` }}
                                    ></div>
                                </div>
                                <h5 className="text-center lh-base title">
                                    Rajani Sivasuthan
                                </h5>
                                <p className="text-center">
                                Sales Representative 
                                </p>
                                <h5 className="text-center lh-base tel">
                                    <TelephoneForward color="black" className="me-2" />
                                    647 986-2305
                                </h5>
                                {/* <h5 className="text-center lh-base">
                                    <Envelope color="black" className="me-2" />
                                    rajani401@hotmail.com
                                </h5> */}
                                </div>
                            </div>
                            </Col>
                            <Col md={4}>
                            <div className="item_wrapper">
                                <div className="item">
                                <div className="circle">
                                    <div
                                    className="agent_photo vm_bg_cover"
                                    style={{ background: `url(${agentRathika})` }}
                                    ></div>
                                </div>
                                <h5 className="text-center lh-base title">
                                    Rathika Senthinathan
                                </h5>
                                <p className="text-center">
                                Sales Representative 
                                </p>
                                <h5 className="text-center lh-base tel">
                                    <TelephoneForward color="black" className="me-2" />
                                    416 722-0088
                                </h5>
                                {/* <h5 className="text-center lh-base">
                                    <Envelope color="black" className="me-2" />
                                    rathika.senthinathan@yahoo.com
                                </h5> */}
                                </div>
                            </div>
                            </Col>
                            <Col md={4}>
                            <div className="item_wrapper">
                                <div className="item">
                                <div className="circle">
                                    <div
                                    className="agent_photo vm_bg_cover"
                                    style={{ background: `url(${agentGowtham})` }}
                                    ></div>
                                </div>
                                <h5 className="text-center lh-base title">
                                    Gowtham Chelvakumaran
                                </h5>
                                <p className="text-center">
                                Sales Representative 
                                </p>
                                <h5 className="text-center lh-base tel">
                                    <TelephoneForward color="black" className="me-2" />
                                    416 799-6269
                                </h5>
                                {/* <h5 className="text-center lh-base">
                                    <Envelope color="black" className="me-2" />
                                    gowtham@solcityrealty.com
                                </h5> */}
                                </div>
                            </div>
                            </Col>

                            <Col md={4}>
                            <div className="item_wrapper">
                                <div className="item">
                                <div className="circle">
                                    <div
                                    className="agent_photo vm_bg_cover"
                                    style={{ background: `url(${agentJeevitha})` }}
                                    ></div>
                                </div>
                                <h5 className="text-center lh-base title">
                                Jeevitha Srinesarasa 
                                </h5>
                                <p className="text-center">
                                Sales Representative 
                                </p>
                                <h5 className="text-center lh-base tel">
                                    <TelephoneForward color="black" className="me-2" />
                                    647 787-3784
                                </h5>
                                {/* <h5 className="text-center lh-base">
                                    <Envelope color="black" className="me-2" />
                                    senthil_ramaswamy@outlook.com
                                </h5> */}
                                </div>
                            </div>
                            
                            </Col>
                            <Col md={4}>
                            <div className="item_wrapper">
                                <div className="item">
                                <div className="circle">
                                    <div
                                    className="agent_photo vm_bg_cover"
                                    style={{ background: `url(${agentMeera})` }}
                                    ></div>
                                </div>
                                <h5 className="text-center lh-base title">
                                Meera Kathirgamanathan 
                                </h5>
                                <p className="text-center">
                                Sales Representative 
                                </p>
                                <h5 className="text-center lh-base tel">
                                    <TelephoneForward color="black" className="me-2" />
                                    647 995-6372
                                </h5>
                                {/* <h5 className="text-center lh-base">
                                    <Envelope color="black" className="me-2" />
                                    senthil_ramaswamy@outlook.com
                                </h5> */}
                                </div>
                            </div>
                            
                            </Col>

                        </Row>
                        <div className="d-flex justify-content-center">
                            <Link to="/joinourteam" className="item" onclick="hide_nav()"><button className="btn_dark text-uppercase">Join Us</button></Link>
                        </div>
                    </div>
                </Container>
            </section> 
            <FooterComponent />
        </>
    );
};
export default OurTeam;