import React from "react";
import { Container, Row, Col,Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import { Link} from "react-router-dom";
import MainBanner from '../assets/img/innerbanner5.webp';
import Img1 from '../assets/img/reinventing.webp';

const TITLE = process.env.REACT_APP_AGENT_NAME+' - About us'

const About = () => {
  return (
    <>
      <Helmet>
          <title>{ TITLE }</title>
      </Helmet>
      <HeaderComponent />
      <div className="menubackdrop"></div>        

      <section className="pt-5">
        <Container className="py-md-5">
          <div className="d-flex justify-content-center">
            <Col md={8}>
              <h1 className="text-center vm_font_bold">Our Story</h1>
            </Col>
          </div>

          <div className="border px-4 py-5 mt-5 shadow-lg">
            <h5 className="text-center">
            Sol City Realty Brokerage opened in 2022; however, the team we have on board has over 30 years of experience in the real estate industry. We come with extensive knowledge, dedication and skills. We have mastered the aspect of marketing, selling and facilitating transactions.   
            </h5>
            <div className="d-flex justify-content-center pt-4 pb-2">
              <Link to="/buy">
                <button className="btn_dark mx-3">
                  Buy With Us
                </button>
              </Link>
              <Link to="/sell">
                <button className="btn_dark mx-3">
                  Sell With Us
                </button>
              </Link>
              <Link to="/invest">
                <button className="btn_dark">
                  Invest With Us
                </button>
              </Link>
            </div>
          </div>
          <div className="pt-5">
            <h2 className="vm_font_bold text-center pt-4">About Us </h2>
            <div className="d-flex justify-content-center">
              <Col md={7}>
                <p className="text-center pt-3">                  
                  Our branch recently opened its new office at 825 Passmore Avenue in Scarborough ON. We specialize in a variety of real estate, custom homes, cottages, condominiums, commercial properties and investment. Our agents have worked in many distinct locations like Toronto, Scarborough, Ajax, Oshawa, Mississauga, Vaughan and Richmond Hill. We are here to make your real estate purchases operate in a smooth transaction. Our team will guide you step by step and assist you in the best way possible. 
                </p>
              </Col>
            </div>  
            <Row className="pt-5 mt-3 pb-4">
              <Col className="pb-4 pb-md-0 col me-3">
                <div className="border border-2 p-4 shadow-lg h-100">
                  <h3 className="text-center vm_font_bold">One stop shop</h3>
                  <p className="text-center pt-3">
                    if you want to sell, buy or invest, we are here
                  </p>
                </div>
              </Col>
              <Col className="pb-4 pb-md-0 col me-3">
                <div className="border border-2 p-4 shadow-lg h-100">
                  <h3 className="text-center vm_font_bold">
                  Transparent
                  </h3>
                  <p className="text-center pt-3">
                    We are dedicated to servicing our clients with integrity, dedication and honesty
                  </p>
                </div>
              </Col>
              <Col className="pb-4 pb-md-0 col me-3">
                <div className="border border-2 p-4 shadow-lg h-100">
                  <h3 className="text-center vm_font_bold">Professionalism</h3>
                  <p className="text-center pt-3">
                    Our experts are dedicated to the integrity of customer service
                  </p>
                </div>
              </Col>
              <Col className="pb-4 pb-md-0 col">
                <div className="border border-2 p-4 shadow-lg h-100">
                  <h3 className="text-center vm_font_bold">Diversity</h3>
                  <p className="text-center pt-3">
                    Our team and our clients are from all parts of the community and we strongly believe in supporting one another 
                  </p>
                </div>
              </Col>
              <Col className="pb-4 pb-md-0 col">
                <div className="border border-2 p-4 shadow-lg h-100">
                  <h3 className="text-center vm_font_bold">Technology</h3>
                  <p className="text-center pt-3">
                    We adopt our strategic plans to today's trend; we integrate innovation and technology into the core of our business practices
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div
          className="mt-5 vm_bg_no_repeat vm_bg_fixed vm_bg_center"
          style={{
            backgroundImage: `url(${Img1})`,
          }}
        >
          <div className="p-5 bg_dark_transparent_5 w-100 h-100">
            <h2 className="text-center vm_font_bold text-white pt-5">
              Join our team
            </h2>
            <div className="d-flex justify-content-center pb-4">
              <Col md={7}>
                <h5 className="text-center text-white pt-3">
                  The expert advice that comes with having a Solcity realtor on your side, is the advantage you need to make your real estate goals your reality
                </h5>
              </Col>
            </div>
            <div className="d-flex justify-content-center pb-5">
              <Link to="/joinourteam">
                <button className="btn_dark_black">
                  Join Us
                </button>
              </Link>
            </div>
          </div>
        </div>  
      </section>
      <FooterComponent />
    </>
  );
};

export default About;
