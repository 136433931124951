import {
  SAVE_SEARCH_DATA,
  SAVE_SEARCH_RESULTS,
  SAVE_FEATURED_LISTINGS,
  COM_SAVE_SEARCH_DATA
} from "./SearchActionTypes";

export const saveSearchData = (data) => async (dispatch) => {
  dispatch({ type: SAVE_SEARCH_DATA, payload: data });
};
export const comSaveSearchData = (data) => async (dispatch) => {
  dispatch({ type: COM_SAVE_SEARCH_DATA, payload: data });
};

export const saveSearchResults = (data) => async (dispatch) => {
  dispatch({ type: SAVE_SEARCH_RESULTS, payload: data });
};

export const saveFeaturedListings = (data) => async (dispatch) => {
  dispatch({ type: SAVE_FEATURED_LISTINGS, payload: data });
};

export const savePropertyTypes = (data) => async (dispatch) => {
  dispatch({ type: SAVE_FEATURED_LISTINGS, payload: data });
};
