import React, { useEffect, useState,Suspense,lazy } from "react";
import { Link, useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

import { Container, Row, Col, Image,Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import Carousel from 'react-bootstrap/Carousel';
import "../../assets/css/home.css";
import "../../assets/css/PostCard.css";

import { fetchingAndSetProperties } from "./../../store/actions/Properties/PropertiesActions";
import {
  saveSearchResults,
  saveSearchData,
  comSaveSearchData,
} from "../../store/actions/Search/SearchActions";

import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";
import HomeWorthForm from "../../componets/Forms/HomeWorthForm";
import Importantlinks from "../../componets/Applications/Layouts/important_links";
import Location from "../../componets/Applications/Layouts/location";
import FeaturedCard from "../../componets/Cards/FeaturedCard";
import BrokerageCard from "../../componets/Cards/BrokerageCard";
import SearchModel from "../../Models/SearchModel";

import sell from '../../assets/img/sell.webp'
import buy from '../../assets/img/buy.webp'
import invest from '../../assets/img/invest.webp'

import video from "../../componets/video/video.mp4";

const Home = (props) => {
  const [count, setCount] = useState(0);
  const [loadings, setLoadings] = useState({
    featuredListings: true
  });
  const [broLoadings, setBroLoadings] = useState({
    brokerageListings: true
  });
  const [featured, setFeatured] = useState([]);
  async function fetchFeaturedListings() {
    let res = await SearchModel.fetchFeaturedListings({
      propertyType: "RESEDENTIAL",
    });
    setFeatured(res);
    setLoadings({ featuredListings: false });
  }
  const [brokerage,setBrokerage] = useState([]);
  async function fetchbrokeragelistings() {
    let res2 = await SearchModel.fetchbrokeragelistings({
      propertyType: "RESEDENTIAL",
    });
    setBrokerage(res2);
    setBroLoadings({ brokerageListings: false });
  }
  useEffect(() => {
    fetchbrokeragelistings();
    fetchFeaturedListings();
  }, []);

  

  // search
  const [search, setSearch] = useState({
    propertyType: "RESEDENTIAL",
    buildingType: "any",
    transactionType: "any",
    keyword: "",
    minPrice: 0,
    maxPrice: 0,
    bed: "any",
    bath: "any",    
    parking: "any",
  });  
  const [comSearch, setComSearch] = useState({
    propertyType: "COMMERCIAL",
    comBuildingType: "any",
    comTransactionType: "any",
    comKeyword: "",
    comMinPrice: 0,
    comMaxPrice: 0,    
    comBed: "any",
    comBath: "any",
    comParking: "any",
  }); 
  const [suggestions, setSuggestions] = useState([]);
  const [searching, setSearching] = useState(false);
  // find suggesstion only if user type any keyword
  useEffect(() => {
    if(search.keyword){
      SearchModel.findSimilarity(search.keyword).then((res) => {
        // matching rating is greater than 0
        if (res.bestMatch.rating > 0) {
          // copy array without duplication
          let data = cloneDeep(suggestions);
          const allInarray = data.filter(
            (values) => values === res.bestMatch.target
          );
          // Make sure the value doesn't exist already
          if (allInarray.length === 0) {
            data.push(res.bestMatch.target);
            setSuggestions(data);
          }
        }
      });
    }
  }, [search.keyword]);
  useEffect(() => {
    if(comSearch.comKeyword){
      SearchModel.findSimilarity(comSearch.comKeyword).then((res) => {
        // matching rating is greater than 0
        if (res.bestMatch.rating > 0) {
          // copy array without duplication
          let data = cloneDeep(suggestions);
          const allInarray = data.filter(
            (values) => values === res.bestMatch.target
          );
          // Make sure the value doesn't exist already
          if (allInarray.length === 0) {
            data.push(res.bestMatch.target);
            setSuggestions(data);
          }
        }
      });
    }
  }, [comSearch.comKeyword]);
  let history = useHistory();
  async function handleFormSubmit(event) {
    event.preventDefault();
    // if (search.keyword === "") {
    //   Swal.fire("Error", "Search keyword cannot be empty", "error");
    //   return;
    // }
    setSearching(true);
    let res = await SearchModel.search(search);
    //console.log(`res => `, res);
    props.saveSearchData(search);
    props.saveSearchResults(res);
    setSearching(false);
    history.push("allresidential", { search });
  }
  async function comHandleFormSubmit(event) {
    event.preventDefault();  
    setSearching(true);
    //console.log(comSearch);
    let res = await SearchModel.comSearch(comSearch);
    props.saveSearchData(comSearch);
    props.saveSearchResults(res);
    setSearching(false);
    history.push("allcommerical", { comSearch });
  }
  const [suggestionClicked, setSuggestionClicked] = useState(false);
  const handleTyping = (value,field) => {
    if(field == 'com'){
      setComSearch({ ...comSearch, comKeyword: value });
    }else{
      setSearch({ ...search, keyword: value });
    } 
    if (suggestionClicked) {
      setSuggestionClicked(false);
    }
  };
  const handleSuggestionClick = (keyword,field) => {
    if(field == 'com'){
      setComSearch({ ...comSearch, comKeyword: keyword });
    }else{
      setSearch({ ...search, keyword: keyword });
    } 
    setSuggestions([]);
    setSuggestionClicked(true);
    setCount(count + 1);
  };
  const handleOnBlur = () => {
    setTimeout(function () {
      setSuggestionClicked(true);
      setCount(count + 1);
    }, 200);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []); 
  
  const TITLE = process.env.REACT_APP_SITE_NAME+' - Home'
  return (
    <>
      <Helmet>
          <title>{ TITLE }</title>
      </Helmet>     
      <HeaderComponent page="home" />
      <React.Fragment>
      <video className='videoTag w-100' controls={false} autoplay="autoplay" loop="true" muted defaultmuted playsinline>
          <source src={video} type='video/mp4' />
      </video> 
        
        <div className="searchengine d-flex justify-content-center position-relative w-100 pt-md-3 pt-4">
            <div className="col-md-9">      
              <div className="d-flex justify-content-center pb-3">
                <h3 className="bg-dark text-white px-3 py-2 text-uppercase">
                  Let Us Make Your Realty Dreams A Reality!{" "}
                </h3>
              </div>    
                <Link to="/joinourteam">   
                  <div className="d-flex justify-content-center px-3">   
                      <h3 className="text-center vm_bg_yellow px-md-3 px-2 py-3 text-uppercase vm_font_bold">Join Our Team</h3>
                      <h3 className="text-center vm_bg_primary px-md-3 px-2 py-3 text-uppercase text-white">No Desk Fee</h3>                  
                  </div>
                </Link>
                <Tabs defaultActiveKey="commercial" id="uncontrolled-tab-example" className="border-0 pt-md-5 pt-4 d-flex justify-content-center">
                    <Tab eventKey="commercial" title="Commercial" className="vm_bg_primary p-4">
                        <form  onSubmit={comHandleFormSubmit}>
                        <Row>
                          <Col md={10} className="pb-3">
                            <input
                                type="text"
                                name="comKeyword"
                                className="form-control rounded-0"
                                placeholder="Commercial property search by MLS #, Address, city or Postal Code"
                                onChange={(e) => handleTyping(e.target.value,'com')}
                                value={comSearch.comKeyword}
                                onBlur={() => handleOnBlur()}
                              />
                            <ul className="list-group">
                              {!suggestionClicked &&
                                suggestions.map((suggestion, index) => (
                                  <li
                                    key={index}
                                    className="list-group-item"
                                    onClick={() => handleSuggestionClick(suggestion,'com')}
                                  >
                                    {suggestion}
                                  </li>
                                ))}
                            </ul>
                          </Col>
                          <Col md={2} className="pb-3">
                            <button type="submit" className="form-control col btn_dark_black">Search</button>                            
                          </Col>
                          </Row>
                        </form>
                    </Tab>
                    <Tab eventKey="residential" title="Residential" className="vm_bg_primary p-4">
                        <form  onSubmit={handleFormSubmit}>
                        <Row>
                          <Col md={10} className="pb-3">
                          <input
                              type="text"
                              name="keyword"
                              className="form-control rounded-0"
                              placeholder="Residential property search by MLS #, Address, city or Postal Code"
                              onChange={(e) => handleTyping(e.target.value,'res')}
                              value={search.keyword}
                              onBlur={() => handleOnBlur()}
                            />
                            <ul className="list-group">
                              {!suggestionClicked &&
                                suggestions.map((suggestion, index) => (
                                  <li
                                    key={index}
                                    className="list-group-item"
                                    onClick={() => handleSuggestionClick(suggestion,'res')}
                                  >
                                    {suggestion}
                                  </li>
                                ))}
                            </ul>
                            </Col>
                            <Col md={2} className="pb-3">
                            <button type="submit" className="form-control col btn_dark_black">Search</button>
                            </Col>
                          </Row>
                        </form>
                    </Tab>
                </Tabs>
                {searching && (
                  <div className="d-flex justify-content-center align-items-center text-white mt-1">
                    <h5 className="text-white me-2"><b>Searching please wait</b></h5>
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}
            </div>
        </div>

        <section className="py-md-5 pb-5 welcome">
          <Container className="py-5">
            <div className="p-5 ContentWrapper">
              <h2 className="text-center text-uppercase vm_font_bold">
                The right agent can lead the way
              </h2>
              <p className="text-center pt-2">
                We are with you in what we do to suit your unique needs
              </p>
              <div className="d-flex justify-content-center pt-2">
                <div className="col-md-2">
                  <hr />
                </div>
              </div>
              <p className="text-center">              
                Winning with integrity, delivering exceptional value and service are the guiding principles at Solcity Realty. Let it be buying or selling residential and commercial properties, Solcity Realty is ready to provide unparalleled customer experience. With over 30 years of combined real estate experience, Solcity Realty team can extend their expertise in residential, commercial, preconstruction projects and land development acquisition across Ontario.   
              </p>
              <div className="d-flex justify-content-center pt-4">
                <Link to="/About">
                  <button className="btn_dark">
                      Read More
                  </button>
                </Link>
              </div>
            </div>
          </Container>
        </section>

        <section className="propertytype">
          <div className="overlay py-5 w-100 h-100">
            <Container>
              <Row className="wrapper pb-5">
                <Col className="pb-4 pb-md-0" md={4}>
                  <Link to="/buy">
                    <div className="item bg-white">
                      <div className="img_wrapper">
                        <div className="commercial"></div>
                      </div>
                      <h4 className="text-center py-3 text-uppercase">
                        Buy
                      </h4>
                    </div>
                  </Link>
                </Col>
                <Col className="pb-4 pb-md-0" md={4}>
                  <Link to="/sell">
                    <div className="item bg-white">
                      <div className="img_wrapper">
                        <div className="residential"></div>
                      </div>
                      <h4 className="text-center py-3 text-uppercase">
                        Sell
                      </h4>
                    </div>
                  </Link>
                </Col>
                <Col className="pb-4 pb-md-0" md={4}>
                  <Link to="/invest">
                    <div className="item bg-white">                     
                      <div className="img_wrapper">
                        <div className="preconstruction"></div>
                      </div>
                      <h4 className="text-center py-3 text-uppercase">
                        Invest
                      </h4>
                    </div>
                  </Link>
                </Col>
              </Row>
              <div className="d-flex justify-content-center align-items-center">
                <div class="bg-logo_small"></div>
                <p className="text-center text-white ps-3 pt-3">
                  We are with you in what we do to suit your unique needs
                </p>
              </div>
            </Container>
          </div>
        </section>

         <section className="pb-5 pt-3 ">
            {brokerage.length > 0 && (
                <Container className="pt-5">
                    <h2 className="text-center text-uppercase vm_font_bold">Featured Listings</h2>
                    <p className="text-center pt-2">Our team of experts will help you find your dream townhouse, condos, apartments in Toronto.</p>
                   
                    {broLoadings.brokerageListings && (
                        <div className="d-flex justify-content-center mt-4">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                        </div>
                    )}                              
                    <div className="pt-5">
                        <Row className="d-flex justify-content-center">
                            {brokerage &&
                                brokerage.map((item, index) => {
                                return (
                                    <Col lg={3} md={6} className="pb-4">
                                        <BrokerageCard key={index} item={item} />
                                    </Col>  
                                );
                            })}
                        </Row>
                    </div>
                    <div className="d-flex justify-content-center pt-2">
                      <Link to="/featuredlistings" className="item" onclick="hide_nav()"><button className="btn_dark text-uppercase">view All Fetaured Properties</button></Link>
                    </div>
                </Container>
            )}
        </section>
        
        <section className="homevalueestimator">
            <div className="overlay py-5">
                <Container className="py-5">
                    <Row>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <p className="text-white text-md-start text-center">How much is my home worth</p>
                            <h2 className="text-white vm_font_bold text-uppercase text-md-start text-center">Home Value Estimator</h2>
                            <p className="text-white text-md-start text-center">Our Leading Research Team Has Put Together The Latest Report With Accurate Market Data.</p>
                            <HomeWorthForm formName="Home Value Estimator" pageName="home" btnName="Get Estimate" showUnit="1"></HomeWorthForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section> 
        
        {/* PropertyListings */}
        <section className="pb-5 pt-3 mt-5">
            <Container className="">
                <h2 className="text-center text-uppercase vm_font_bold">Let's find you your ideal home</h2>
                <p className="text-center pt-2">Find your next residential or commercial property with Solcity Realty </p>
                
                <div className="propoerty_tab">
                    {loadings.featuredListings && (
                    <div className="d-flex justify-content-center mt-4">
                        <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                        </div>
                    </div>
                    )}
                    
                <div className=" pt-5">
                    <Row className="d-flex justify-content-center">
                    {featured &&
                        featured.map((item, index) => {
                            return (
                            <Col lg={3} md={6} className="pb-4" key={index}>
                                <FeaturedCard key={index} item={item} />
                            </Col>
                            );
                        })}
                    </Row>
                </div>
                </div>
                <div className="d-flex justify-content-center pt-5">
                  <Link to="/allresidential" className="item" onclick="hide_nav()"><button className="btn_dark text-uppercase">view All Residential Properties</button></Link>
                </div>
            </Container>
        </section>              
              
        <section className="parallax2">
            <div className="overlay py-5">
                <Container className="py-5">
                    <Row>
                        <Col md={6}>
                            <h2 className="text-white vm_font_bold text-uppercase text-md-start text-center">Stay on top of your local Real Estate market</h2>
                            <p className="text-white pt-3 text-md-start text-center">Get expert advice on buying and selling a house, and search Canadian real estate listings. View homes and cottages for sale from {process.env.REACT_APP_AGENT_NAME}. We effectively coordinate the purchase and sale of real estate, properties, residential homes, town-homes, condos.</p>
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                </Container>
            </div>
        </section>

        <section className="py-5 findtools">
            <Container className="py-5">
                <h2 className="text-center text-uppercase vm_font_bold">Find our Realestate Tools</h2>
                <p className="text-center pt-2">Take advantages of these resources</p>
             

                <div class="d-flex justify-content-center">
                    <div className="row col-md-11 col-12 d-md-flex justify-content-between pt-5">
                        
                          <div className="item col-6 col-md-2 pb-5 pb-md-0">
                            <Link to="/pricedropalert1">
                            <div className="d-flex justify-content-center pb-3">
                                <div className="bg-price_drop_alert icon"></div>
                            </div>
                            <p className="text-center">Price Drop<br/>Alert</p>
                            </Link>
                          </div>
                        
                        <div className="item col-6 col-md-2 pb-5 pb-md-0">
                          <Link to="/homeworth1">
                            <div className="d-flex justify-content-center pb-3">
                                <div className="bg-home_value_estimator icon"></div>
                            </div>
                            <p className="text-center">Home Value<br/>Estimator</p>
                          </Link>
                        </div>
                        <div className="item col-6 col-md-2 pb-5 pb-md-0">
                          <Link to="/allresidential">
                            <div className="d-flex justify-content-center pb-3">
                                <div className="bg-findmy_propoerty icon"></div>
                            </div>
                            <p className="text-center">Find my<br/>Property</p>
                            </Link>
                        </div>
                        <div className="item col-6 col-md-2 pb-5 pb-md-0">
                        <Link to="/mortgageloan">
                            <div className="d-flex justify-content-center pb-3">
                                <div className="bg-mortgage_calculator icon"></div>
                            </div>
                            <p className="text-center">Mortgage<br/>Calculator</p>
                            </Link>
                        </div>
                        <div className="item col-6 col-md-2 pb-5 pb-md-0">
                        <Link to="/landtransfer">
                            <div className="d-flex justify-content-center pb-3">
                                <div className="bg-land_transfer icon"></div>
                            </div>
                            <p className="text-center">Land Transfer<br/>Calculator</p>
                            </Link>
                        </div>
                        <div className="item col-6 col-md-2 pb-5 pb-md-0">
                        <Link to="/cmhcpremium">
                            <div className="d-flex justify-content-center pb-3">
                                <div className="bg-prospects_guide icon"></div>
                            </div>
                            <p className="text-center">CMHC<br/>Premium</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        </section>

      </React.Fragment> 
      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) => ({
  getAllProperties: (data) => {
    dispatch(fetchingAndSetProperties(data));
  },
  saveSearchResults: (data) => {
    dispatch(saveSearchResults(data));
  },
  saveSearchData: (data) => {
    dispatch(saveSearchData(data));
  },
  comSaveSearchData: (data) => {
    dispatch(comSaveSearchData(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
