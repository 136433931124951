import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";

export default function NewGroupForm({ oncreate }) {
  const formik = useFormik({
    initialValues: {
      group_type: get("", "group_type", ""),
    },
    validationSchema: Yup.object({
      group_type: Yup.string().required("This field is requried"),
    }),
    onSubmit: (values) => {
      oncreate(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div class="form-group">
        <label for="group-type" className="mb-1">
          Group Type
        </label>
        <input
          type="text"
          class="form-control"
          id="group-type"
          aria-describedby="subjectHelp"
          value={formik.values.group_type}
          onChange={(value) =>
            formik.setFieldValue("group_type", value.target.value)
          }
          onBlur={() => formik.setFieldTouched("group_type")}
        />
        {Boolean(formik.errors.group_type) && formik.touched.group_type ? (
          <small
            id="subjectError"
            class={
              "form-text " + Boolean(formik.errors.group_type)
                ? "text-danger"
                : "text-muted"
            }
          >
            {formik.errors.group_type}
          </small>
        ) : (
          ""
        )}
      </div>

      <button type="submit" class="btn btn-primary">
        Create
      </button>
    </form>
  );
}
