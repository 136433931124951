import React, { useEffect, useState } from "react";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";

import {
  getBlogPost,
  saveBlogPosts,
  saveBlogCategories,
} from "../store/actions/App/AppActions";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import BlogModel from "../Models/BlogModel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get, find } from "lodash";
import Swal from "sweetalert2";
import axios from "../common/ajax";
import LoadingButton from "../componets/LoadingButton";

import BlogComment from "../Models/BlogComment";
import Spinner from "../componets/Spinner";

import "../assets/css/BlogDetails.css";
const TITLE = process.env.REACT_APP_SITE_NAME+' - Blog Details'  

const BlogDetails = (props) => {
  const History = useHistory();
  const params = useParams();

  const [post, setPost] = useState(props.state.blogPost);
  const [postNotFound, setPostNotFound] = useState(false);

  useEffect(() => {
    const fetchPostData = async () => {
      const res = await BlogModel.fetchBlogPostData(
        params.category,
        params.postId
      );

      if (res.data) {
        if (res.data.post === null) {
          setPostNotFound(true);
        } else {
          setPost(res.data.post);
        }

        if (res.data.relatedTopics) {
          props.saveBlogPosts(res.data.relatedTopics);
        }
      }

      if (props.state.blogCategories.length <= 0) {
        const response = await BlogModel.getAllCategories2();
        props.saveBlogCategories(response.data);
      }
    };

    fetchPostData();

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [params.postId]);

  const [loading, setLoading] = useState(0);

  async function handleWorthForm(payload) {
      try {
        await BlogComment.create({
          ...payload,
        });
  
        Swal.fire(`Done`, `Submitted successfully`, "success");
        setLoading(false);
  
        formik.setFieldValue("name", "");
        formik.setFieldValue("email", "");
        formik.setFieldValue("comment", "");
      } catch (error) {
        console.log("Error", error);
        Swal.fire(`Error`, `Something went wrong.`, "error");
        setLoading(false);
      }
    }
  
  const formik = useFormik({
      initialValues: {
        name: get("", "name", ""),
        email: get("", "email", ""),
        comment: get("", "comment", ""),
        category: get("", "category", params.category),
        postId: get("", "postId", params.postId),
      },
      validationSchema: Yup.object({
          name: Yup.string().min(3, 'Name must be between 3 and 50 characters').max(50, 'Name must be between 3 and 50 characters').required("This field is requried"),
          email: Yup.string().email('Invalid email').required('This field is required'),
          comment: Yup.string().required("This field is requried")
      }),
      onSubmit: (values) => {
      setLoading(true);
          handleWorthForm(values);
      },
  });

  return (
    <>
      <Helmet>
          <title>{ TITLE }</title>
      </Helmet>
      <HeaderComponent />
      <div className="menubackdrop"></div>

      <section className="py-5 bg-light">
        <Container className="py-4">
            <Row>
                <Col md={8}>
                    <div className="border p-4 vm_boxshadow_1 bg-white">
                        <Image className="w-100" src={post.featured_image_url}/>
                        <h2 className="pt-4">{post.title}</h2>
                        <p className="pt-3" dangerouslySetInnerHTML={{ __html: post.content }}></p>
                       
                    </div>

                    <div className="border p-4 vm_boxshadow_1 mt-5 bg-white">
                        <h3 className="vm_font_bold pb-3 mb-3 border-bottom">Leave a Reply</h3>

                        <div className="row pb-4">
                          <div className="col-md-6 col-xs-12">
                              <input type="hidden" name="category" value={params.category} onChange={(e) =>formik.setFieldValue("category", e.target.value)} onBlur={() => formik.setFieldTouched("category")}/>
                              <input type="hidden" name="postId" value={params.postId} onChange={(e) =>formik.setFieldValue("postId", e.target.value)} onBlur={() => formik.setFieldTouched("postId")}/>

                              <input type="text" name="name" id="name" className="form-control rounded-0 py-3" placeholder="Name *" value={formik.values.name} onChange={(e) =>formik.setFieldValue("name", e.target.value)} onBlur={() => formik.setFieldTouched("name")}/>
                              {Boolean(formik.errors.name) &&
                              formik.touched.name ? (
                                  <small
                                  id="name"
                                  className={
                                      "form-text " + Boolean(formik.errors.name)
                                      ? "text-danger"
                                      : "text-muted"
                                  }
                                  >
                                  {formik.errors.name}
                                  </small>
                              ) : (
                                  ""
                              )}
                          </div>
                          <div className="col-md-6 col-xs-12">
                              <input type="email" name="email" id="email" className="form-control rounded-0 py-3" placeholder="Email *" value={formik.values.email} onChange={(e) =>formik.setFieldValue("email", e.target.value)} onBlur={() => formik.setFieldTouched("email")}/> 

                              {Boolean(formik.errors.email) &&
                              formik.touched.email ? (
                                  <small
                                  id="email"
                                  className={
                                      "form-text " + Boolean(formik.errors.email)
                                      ? "text-danger"
                                      : "text-muted"
                                  }
                                  >
                                  {formik.errors.email}
                                  </small>
                              ) : (
                                  ""
                              )} 
                          </div>                
                        </div>  
                        <div className="row pb-4">
                          <div className="col-md-12 col-xs-12">
                            <textarea className="form-control rounded-0 border mt-3" name="comment" id="comment" placeholder="Comment *" style={{height: '200px'}} value={formik.values.comment} onChange={(e) =>formik.setFieldValue("comment", e.target.value)} onBlur={() => formik.setFieldTouched("comment")}></textarea>
                            {Boolean(formik.errors.comment) &&
                            formik.touched.comment ? (
                                <small
                                id="comment"
                                className={
                                    "form-text " + Boolean(formik.errors.comment)
                                    ? "text-danger"
                                    : "text-muted"
                                }
                                >
                                {formik.errors.comment}
                                </small>
                            ) : (
                                ""
                            )}
                          </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col-md-12 col-xs-12">
                              <LoadingButton
                                type="submit"
                                className=""
                                title="Submit Comment"
                                style="btn_dark px-3 py-2 mt-3"
                                loading={loading}
                                handleClick={() => formik.submitForm()}
                            />
                          </div>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="border p-4 vm_boxshadow_1 bg-white">
                        <h3 className="vm_font_bold pb-3 mb-3 border-bottom">Lastest Blogs</h3>
                        {props.state.blogPosts &&
                        props.state.blogPosts.map((post, index) => (
                        <Link className="tg-primary-text" to={`/blogs/${post.category}/${post.id}`}>
                          <Row className="d-flex border-bottom pb-3 mb-4"  key={index}>
                            <Col md={4}><Image className="w-100" src={post.featured_image_url}/></Col>
                            <Col md={8}>
                                <p > {post.title}</p>
                                <p className="text-muted"> {moment(post.updated_at).format("DD MMM YYYY")}</p>
                            </Col>
                          </Row>
                        </Link>
                      ))}
                    </div>
                    <div className="border p-4 vm_boxshadow_1 mt-4 bg-white">
                        <h3 className="vm_font_bold pb-3 mb-3 border-bottom">Lastest Tags</h3>
                        <div className="d-flex">
                          {props.state.blogCategories.length > 0 && props.state.blogCategories.map((category, index) => (
                            <Link key={index} to={`/blogs/${category.category}`}>
                                <div className="bg-black px-3 py-1 text-white">{category.category}</div>      
                            </Link>
                          ))} 
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </section>

                  
      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state: state.appState };
};

const mapDispatchToProps = (dispatch) => ({
  saveBlogPosts: (payload) => {
    dispatch(saveBlogPosts(payload));
  },
  getBlogPost: (id) => {
    dispatch(getBlogPost(id));
  },
  saveBlogCategories: (payload) => {
    dispatch(saveBlogCategories(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);
