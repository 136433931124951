import React, { useEffect, useState } from "react";
import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";
import { Container, Row, Col,Image } from "react-bootstrap";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { Link,useHistory, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import BrokerageCard from "../../componets/Cards/BrokerageCard";
import SearchModel from "../../Models/SearchModel";

const TITLE = process.env.REACT_APP_SITE_NAME+' - Join Our Team'
const FeaturesLlistings = (props) => {
const history = useHistory();
const params = useParams();
const [broLoadings, setBroLoadings] = useState({
    brokerageListings: true
  });

  const [brokerage,setBrokerage] = useState([]);
  async function fetchbrokeragelistings() {
    let res2 = await SearchModel.fetchbrokeragelistings({
      propertyType: "RESEDENTIAL",
    });
    setBrokerage(res2);
    setBroLoadings({ brokerageListings: false });
  }
  useEffect(() => {
    fetchbrokeragelistings();
  }, []);

  return (
    <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />        
            <div className="menubackdrop"></div>        
          
            <section className="pb-5 pt-3 ">
            {brokerage.length > 0 && (
                <Container className="pt-5">
                    <h2 className="text-center text-uppercase vm_font_bold">Featured Listings</h2>
                    <p className="text-center pt-2">Our team of experts will help you find your dream townhouse, condos, apartments in Toronto.</p>
                   
                    {broLoadings.brokerageListings && (
                        <div className="d-flex justify-content-center mt-4">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                        </div>
                    )}                              
                    <div className="pt-5">
                        <Row className="d-flex justify-content-center">
                            {brokerage &&
                                brokerage.map((item, index) => {
                                return (
                                    <Col lg={3} md={6} className="pb-4">
                                        <BrokerageCard key={index} item={item} />
                                    </Col>  
                                );
                            })}
                        </Row>
                    </div>
                </Container>
            )}
        </section>  
           
        <FooterComponent />
    </>
  );
};


const mapStateToProps = (state) => {
    return { state: state.appState };
  };
  
  const mapDispatchToProps = (dispatch) => ({
   
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(FeaturesLlistings);