import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TelephoneForward, Envelope, PinMap } from 'react-bootstrap-icons';

import '../../../assets/css/footer.css'
import FooterLogo from './../../../assets/img/agent_main_logo.webp'

const Footer = () => {
  return (
     <div>
        <footer className="bg-dark">
            <div className="overlay pt-5">
                <Container className="py-5">
                    <Row>
                        <Col lg={3} md={12}>
                            <Image src={FooterLogo} alt={window.sessionStorage.getItem("VM_APP_SITE_NAME")}/>
                            <p className="pt-3">
                            Let Us Make Your Realty Dreams A Reality
                            </p>
                        </Col>
                        <Col lg={3} md={4} className="pt-5 ps-lg-5">
                            <h3 className="text-uppercase pb-3">Important Links</h3>
                            <div>
                                <Link to="/" className="item" onclick="hide_nav()">
                                    <p>Home</p>
                                </Link> 
                                <Link to="/about" className="item" onclick="hide_nav()">
                                    <p>About Us</p>
                                </Link> 
                                <Link to="/joinourteam" className="item" onclick="hide_nav()">
                                    <p>Join Our Team</p>
                                </Link>
                                <Link to="/contact-us" className="item" onclick="hide_nav()">
                                    <p>Contact Us</p>
                                </Link>
                                <Link to="/blogs" className="item" onclick="hide_nav()">
                                    <p>Blogs</p>
                                </Link>
                            </div>
                        </Col>
                        <Col lg={3} md={4} className="pt-5 ps-lg-5">
                            <h3 className="text-uppercase pb-3">Useful Links</h3>
                            <div>
                                <Link to="/pricedropalert1" className="item" onclick="hide_nav()"> <p>Price Drop Alert</p></Link>
                                <Link to="/homeworth1" className="item" onclick="hide_nav()"> <p>Home Value Estimator</p></Link>
                                <Link to="/homevalue1" className="item" onclick="hide_nav()"> <p>Find My Dream Home</p></Link>
                                <Link to="/calculators" className="item" onclick="hide_nav()"> <p>Calculators</p></Link>

                            </div>
                        </Col>

                        <Col lg={3} md={4} className="pt-5">
                            <h3 className="text-uppercase pb-3">Contact us</h3>
                            <div>
                                <div className="d-flex">
                                    <PinMap className="me-2 mt-2" color="white"/>
                                    <p>{process.env.REACT_APP_ADDRESS1_1}<br/>{process.env.REACT_APP_ADDRESS1_2}</p>
                                </div>
                                <a href="tel:{window.sessionStorage.getItem('VM_APP_PHONE_NUMBER')}">
                                    <div className="d-flex align-items-center">
                                        <TelephoneForward className="me-2" color="white"/>
                                        <p>{window.sessionStorage.getItem("VM_APP_PHONE_NUMBER")}</p>
                                    </div>
                                </a>
                                <a href="mailto:{window.sessionStorage.getItem('VM_APP_EMAIL')}">
                                    <div className="d-flex align-items-center">
                                        <Envelope className="me-2" color="white"/>
                                        <p>{window.sessionStorage.getItem("VM_APP_EMAIL")}</p>
                                    </div>
                                </a>
                            </div>
                            <div className="d-flex align-items-center pt-3">
                                <a href={window.sessionStorage.getItem("VM_APP_FB_LINK")} target="_blank"><div className="bg-facebook"></div></a>
                                <a href={window.sessionStorage.getItem("VM_APP_INSTA_LINK")} target="_blank"><div className="bg-instagram ms-3"></div></a>
                            </div>
                        </Col>                      
                    </Row>
                </Container>
            </div>
        </footer>
        <p className="vm_bg_gray text-center text-white py-3">©{(new Date().getFullYear())} {process.env.REACT_APP_SITE_NAME}. All rights reserved.</p>
    </div>
  );
};

export default Footer;
