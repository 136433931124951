import React from "react";
import { Helmet } from "react-helmet";
import './../assets/css/homevalue.css'
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import HomeWorthForm from "../componets/Forms/HomeWorthForm";

const TITLE = process.env.REACT_APP_SITE_NAME+' - Find My Dream Home'

const HomeValue1 = () => {
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <HeaderComponent />
            <div className="menubackdrop"></div>

            <section className="homevalue">
                <div className="homevalue_1">
                    <Container>
                        <Row  className="d-flex justify-content-center">
                            <Col md={7}>
                                <h2 className="vm_font_bold text-center text-uppercase pt-5 mt-5 text-white">Finding your Dream Home has<br/>never been this easy!</h2>
                                <p className="text-center pt-3 pb-4 text-white">Get instant VIP email alerts for recently reduced listings in any area!</p>
                                <HomeWorthForm formName="Find My Dream Home" pageName="Find My Dream Home" btnName="Get Estimate" showUnit="0"></HomeWorthForm>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>          
                
            <FooterComponent />
        </>
    );
};
export default HomeValue1;