import moment from "moment";
import { Image } from "react-bootstrap";

const PostCard = ({ blog, history, getBlogPost }) => {
  return (
    <div className="bg-white vm_boxshadow_1 border" style={{ cursor: "pointer" }} onClick={() => {
      getBlogPost(blog.id);
      history.push(`/blogs/${blog.category}/${blog.id}`);
    }}>
        <div>
            <Image className="w-100" src={blog.featured_image_url}/>
        </div>
        <div className="p-3" style={{ cursor: "pointer" }}
            onClick={() => {
              getBlogPost(blog.id);
              history.push(`/blogs/${blog.category}/${blog.id}`);
            }}>
            <p className="vm_font_bold">{blog.title}</p>
            <p style={{ fontSize: "15px",lineHeight:"17px"}}>{blog.excerpt}</p>
            <p>{moment(blog.updated_at).format("DD MMM YYYY")}</p>
            <p style={{ cursor: "pointer" }}
                  onClick={() => {
                    getBlogPost(blog.id);
                    history.push(`/blogs/${blog.category}/${blog.id}`);
                  }}>Read More</p>
        </div>
    </div>              
  );
};

export default PostCard;
