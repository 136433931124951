import React from "react";
import {Container, Row, Col} from 'react-bootstrap'

class Location extends React.Component {
    render() {
        return (
            <div class="py-5">
                <Container className="py-5">
                    <h2 align="center" className="vm_fm_secondary text-uppercase pb-5">Where Should you Live?</h2>
                    <Row>
                        <Col md={3} className="pb-4" >
                            <a href="/estate/Toronto">
                                <div className="border border-2 p-4 d-flex justify-content-center align-items-center flex-column">
                                    <div className="bg-location mb-3"></div>
                                    <h3 align="center" className="vm_font_bold">Toronto</h3>
                                    <p align="center" className="mb-0">Properties</p>
                                </div>
                            </a>
                        </Col>
                        
                        <Col md={3} className="pb-4">
                        <a href="/estate/Mississauga">

                            <div className="border border-2 p-4 d-flex justify-content-center align-items-center flex-column">
                                <div className="bg-location mb-3"></div>
                                <h3 align="center" className="vm_font_bold">Mississauga</h3>
                                <p align="center" className="mb-0">Properties</p>
                            </div></a>
                        </Col>
                        <Col md={3} className="pb-4">
                        <a href="/estate/Richmond Hill">

                            <div className="border border-2 p-4 d-flex justify-content-center align-items-center flex-column">
                                <div className="bg-location mb-3"></div>
                                <h3 align="center" className="vm_font_bold">Richmond Hill</h3>
                                <p align="center" className="mb-0">Properties</p>
                            </div></a>
                        </Col>
                        <Col md={3} className="pb-4">
                        <a href="/estate/Pickering">

                            <div className="border border-2 p-4 d-flex justify-content-center align-items-center flex-column">
                                <div className="bg-location mb-3"></div>
                                <h3 align="center" className="vm_font_bold">Pickering</h3>
                                <p align="center" className="mb-0">Properties</p>
                            </div>
                            </a>
                        </Col>
                        <Col md={3} className="pb-4">
                        <a href="/estate/Markham">

                            <div className="border border-2 p-4 d-flex justify-content-center align-items-center flex-column">
                                <div className="bg-location mb-3"></div>
                                <h3 align="center" className="vm_font_bold">Markham</h3>
                                <p align="center" className="mb-0">Properties</p>
                            </div></a>
                        </Col>
                        <Col md={3} className="pb-4">
                        <a href="/estate/Oshawa">

                            <div className="border border-2 p-4 d-flex justify-content-center align-items-center flex-column">
                                <div className="bg-location mb-3"></div>
                                <h3 align="center" className="vm_font_bold">Oshawa</h3>
                                <p align="center" className="mb-0">Properties</p>
                            </div></a>
                        </Col>
                        <Col md={3} className="pb-4">
                        <a href="/estate/Ajax">

                            <div className="border border-2 p-4 d-flex justify-content-center align-items-center flex-column">
                                <div className="bg-location mb-3"></div>
                                <h3 align="center" className="vm_font_bold">Ajax</h3>
                                <p align="center" className="mb-0">Properties</p>
                            </div></a>
                        </Col>
                        <Col md={3} className="pb-4">
                        <a href="/estate/Scarborough">

                            <div className="border border-2 p-4 d-flex justify-content-center align-items-center flex-column">
                                <div className="bg-location mb-3"></div>
                                <h3 align="center" className="vm_font_bold">Scarborough</h3>
                                <p align="center" className="mb-0">Properties</p>
                            </div></a>
                        </Col>                       
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Location;