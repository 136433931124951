import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Home from "./pages/Home/Home";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Showings from "./pages/Showings";
import ShowingsList from "./pages/ShowingsList";
import EstateHome from "./pages/Estate/EstateHome";
import EstateDetails from "./pages/Estate/EstateDetails";
// import Spinner from "./componets/Spinner";
// import AdminHome from "./pages/admin/home";
import adminRoutes from "./routes/adminRoutes";
import Login from "./pages/admin/pages/Login";
import BlogDetails from "./pages/BlogDetail";
import Blogs from "./pages/Blogs";
import PropList from './pages/Proplist';
import Invest from './pages/Invest';
import Buy from './pages/Buy';
import Sell from './pages/Sell';
import Calculators from './pages/Calculators';
import LandTransfer from './pages/LandTransfer';
import MortgageLoan from './pages/MortgageLoan';
import MortgageAffordability from './pages/MortgageAffordability';
import CmhcPremium from './pages/CmhcPremium';
import HomeWorth1 from './pages/HomeWorth1'
import HomeWorth2 from './pages/HomeWorth2'
import HomeVlaue1 from './pages/HomeValue1'
import HomeVlaue2 from './pages/HomeValue2'
import PriceDropAlert1 from './pages/PriceDropAlert1'
import PriceDropAlert2 from './pages/PriceDropAlert2'
import PriceDropAlert3 from './pages/PriceDropAlert3'
import Development from './pages/Development'
import Residential from "./pages/Search/Search";
import PropertyDetails from './pages/PropertyDetails'
import OurTeam from './pages/OurTeam'
import JoinOurTeam from './pages/JoinOurTeam'
import AgentContact from './pages/AgentContact'
import Commerical from "./pages/Search/ComSearch";
import FeaturesLlistings from "./pages/Search/FeaturesLlistings";

function App(props) {
  /* useEffect(() => {
    console.log(`props.appState => `, props.appState);
  }, []); */

  const privateRoute = (route, index) => {
    const AUTH = localStorage.getItem("auth");

    if (!AUTH) {
      return (
        <Route path="/admin/*">
          <Redirect to={{ pathname: "/admin/login" }} />
        </Route>
      );
    }

    return (
      <Route
        key={index}
        path={`/admin/${route.path}`}
        component={route.component}
        exact
      />
    );
  };

  return (
    <>
      <Router>
        <Switch>
          <Route path="/admin/login" exact>
            <Login />
          </Route>

          {adminRoutes.map((route, index) => privateRoute(route, index))}

          {/* {adminRoutes.map((route, index) => (
            <Route
              path={`/admin/${route.path}`}
              component={route.component}
              exact
              key={index}
            />
          ))}*/}

          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/estate/:locationName" exact>
            <EstateHome />
          </Route>

          <Route path="/estate/details/:propertyId/:propertyType" exact>
            <EstateDetails />
          </Route>

          <Route path="/about" exact>
            <About />
          </Route>
          <Route path="/development" exact>
            <Development />
          </Route>
          <Route path="/ourteam" exact>
            <OurTeam />
          </Route>
          <Route path="/joinourteam" exact>
            <JoinOurTeam />
          </Route>
          <Route path="/ag/:slug" exact>
            <Home />
          </Route>
          <Route path="/propertydetails" exact>
            <PropertyDetails />
          </Route>
          <Route path="/homeworth" exact>
            <HomeWorth1 />
          </Route>
          <Route path="/homevalue" exact>
            <HomeVlaue1 />
          </Route>
          <Route path="/homevalue2" exact>
            <HomeVlaue2 />
          </Route>
          <Route path="/pricedropalert" exact>
            <PriceDropAlert1 />
          </Route>
          <Route path="/pricedropalert2" exact>
            <PriceDropAlert2 />
          </Route>
          <Route path="/pricedropalert3" exact>
            <PriceDropAlert3 />
          </Route>

          <Route path="/contact-us" exact>
            <ContactUs />
          </Route>

          <Route path="/invest" exact>
            <Invest />
          </Route>
          <Route path="/buy" exact>
            <Buy />
          </Route>
          <Route path="/sell" exact>
            <Sell />
          </Route>
          <Route path="/calculators" exact>
            <Calculators />
          </Route>
          <Route path="/landtransfer" exact>
            <LandTransfer />
          </Route>
          <Route path="/mortgageloan" exact>
            <MortgageLoan />
          </Route>
          <Route path="/mortgageaffordability" exact>
            <MortgageAffordability />
          </Route>
          <Route path="/cmhcpremium" exact>
            <CmhcPremium />
          </Route>
          <Route path="/residential/:buildingType" exact>
            <Residential />
          </Route>
          <Route path="/allresidential" exact>
            <Residential />
          </Route>
          <Route path="/commerical/:buildingType" exact>
            <Commerical />
          </Route>
          <Route path="/allcommerical" exact>
            <Commerical />
          </Route>
          <Route path="/featuredlistings" exact>
            <FeaturesLlistings />
          </Route>


          <Route path="/showings" exact>
            <Showings />
          </Route>

          <Route path="/showings/:id" exact>
            <ShowingsList />
          </Route>

          <Route path="/blogs/:category" exact>
            <Blogs />
          </Route>

          <Route path="/blogs" exact>
            <Blogs />
          </Route>

          <Route path="/blogs/:category/:postId" exact>
            <BlogDetails />
          </Route>

          <Route path="*">
            <Redirect to={{ pathname: "/" }} />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

const mapStateToProps = (state) => {
  // console.log("App.js => ", state);
  // console.log(state);
  return { appState: state.appState };
};

export default connect(mapStateToProps, null)(App);
