import React, { useEffect, useState,Suspense,lazy } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { CaretDownFill } from 'react-bootstrap-icons';
import "../../../assets/css/header.css";
import { Container, Row, Col, Image,Nav} from "react-bootstrap";
import { Link } from "react-router-dom";
import MainLogo from '../../../assets/img/agent_main_logo.webp'
// import * as Icon from 'react-bootstrap-icons';
import Navbar from '../../../componets/Applications/Header/Mainmenu';
import UserModel from "../../../Models/UserModel";

var VM_APP_AGENT_NAME = process.env.REACT_APP_AGENT_NAME;
var VM_APP_AGENT_TITLE = process.env.REACT_APP_AGENT_TITLE;
var VM_APP_PHONE_NUMBER = process.env.REACT_APP_PHONE_NUMBER;
var VM_APP_EMAIL = process.env.REACT_APP_EMAIL;
var VM_APP_FB_LINK = process.env.REACT_APP_FB_LINK;
var VM_APP_TWITTER_LINK = process.env.REACT_APP_TWITTER_LINK;
var VM_APP_YOUTUBE_LINK = process.env.REACT_APP_YOUTUBE_LINK;
var VM_APP_INSTA_LINK = process.env.REACT_APP_INSTA_LINK;
var agent_id=null;

var url_path=window.location.pathname;
var substring='/ag';
var is_ag_page=url_path.includes(substring);

const Header = (props) => {
    const [headerLoadings, setHeaderLoadings] = useState({
        HomeContent: true
    });
    
    async function setHomepageData() {
        if(is_ag_page){
            const url_array = url_path.split("/");
            if(url_array[2]){
        
                var slug=url_array[2];  
                try {
                    UserModel.fetchUserDetails(slug); 
                    setHeaderLoadings({ HomeContent: false });
                }  catch (error) {
                }                         
            }       
        }else{
            if (!window.sessionStorage.getItem('VM_APP_EMAIL')) {
                window.sessionStorage.setItem("VM_AGENT_SET", 0);
                window.sessionStorage.setItem("VM_APP_AGENT_NAME", VM_APP_AGENT_NAME);
                window.sessionStorage.setItem("VM_APP_EMAIL", VM_APP_EMAIL);
                window.sessionStorage.setItem("VM_APP_AGENT_TITLE", VM_APP_AGENT_TITLE);
                window.sessionStorage.setItem("VM_APP_PHONE_NUMBER", VM_APP_PHONE_NUMBER);
                window.sessionStorage.setItem("VM_APP_FB_LINK", VM_APP_FB_LINK);
                window.sessionStorage.setItem("VM_APP_TWITTER_LINK", VM_APP_TWITTER_LINK);
                window.sessionStorage.setItem("VM_APP_YOUTUBE_LINK", VM_APP_YOUTUBE_LINK);
                window.sessionStorage.setItem("VM_APP_INSTA_LINK", VM_APP_INSTA_LINK);
                window.sessionStorage.setItem("VM_AGENT_ID", agent_id);    
            }    
        }
    }     
   
    useEffect(() => {
        setHomepageData();
    }, []);
  // console.log("props header", props.state.appState.showings);
  // console.log("page", props.page);
    const history = useHistory();
    function openmenu() {
        // alert("OK");
        document.getElementById("menu").style.opacity = "9";
        document.getElementById("menu").style.transform  = "scaleY(1)";
        document.getElementById("menu").style.transition = "all 0.5s";
        document.getElementById("menu_open").style.display  = "none";
        document.getElementById("menu_close").style.display  = "block";
    }
    function closenav() {
        //alert("OK");
        document.getElementById("menu").style.opacity = "0";
        document.getElementById("menu").style.transform  = "scaleY(0)";
        document.getElementById("menu").style.transition = "all 0.5s";
        document.getElementById("menu_close").style.display  = "none";
        document.getElementById("menu_open").style.display  = "block";
    }
    function openmenu_mob() {
        // alert("OK");
        document.getElementById("menu_mob").style.opacity = "9";
        document.getElementById("menu_mob").style.transform  = "scaleY(1)";
        document.getElementById("menu_mob").style.transition = "all 0.5s";
        document.getElementById("menu_open_mob").style.display  = "none";
        document.getElementById("menu_close_mob").style.display  = "block";
    }
    function closenav_mob() {
        //alert("OK");
        document.getElementById("menu_mob").style.opacity = "0";
        document.getElementById("menu_mob").style.transform  = "scaleY(0)";
        document.getElementById("menu_mob").style.transition = "all 0.5s";
        document.getElementById("menu_close_mob").style.display  = "none";
        document.getElementById("menu_open_mob").style.display  = "block";
    }

    return (
        <div>           
            <header className="header_desktop position-absolute w-100 py-2">
                <Container>
                    <Row>
                        <Col md={3}>
                            <Link to="/">
                                <Image src={MainLogo} alt={window.sessionStorage.getItem("VM_APP_SITE_NAME")}/>
                            </Link>
                        </Col>
                        <Col md={9} className="d-flex align-items-end flex-column justify-content-center pt-3">
                            <div className="d-flex justify-content-end align-items-center pe-3 me-1 pb-1">
                                <div className="d-flex me-3">
                                    <a href={window.sessionStorage.getItem("VM_APP_FB_LINK")} target="_blank" rel="noopener noreferrer">
                                        <div className="bg-facebook"></div>
                                    </a>
                                    {/* <a href={window.sessionStorage.getItem("VM_APP_TWITTER_LINK")} target="_blank" rel="noopener noreferrer">
                                        <div className="bg-twitter mx-2"></div>
                                    </a> */}
                                    <a href={window.sessionStorage.getItem("VM_APP_INSTA_LINK")} target="_blank" rel="noopener noreferrer">
                                        <div className="bg-instagram mx-2"></div>
                                    </a>
                                </div>
                                <p className="text-white text-uppercase">{window.sessionStorage.getItem("VM_APP_EMAIL")}</p>
                                <Link to="/joinourteam"><div className="border px-3 py-1 text-white ms-3 d-flex align-items-center ms-3 text-uppercase">
                                    <div className="bg-agent me-2"></div>Join Us
                                </div></Link>
                            </div>
                            <div className="pt-2">
                                <div>
                                    <Nav>
                                        <Navbar/>
                                    </Nav>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>

            <header className="header_tab position-relative w-100 py-2">                   
                <div>
                    <Nav class="position-fixed" id="menu" style={{opacity: '0', transform: 'scaleY(0)', zIndex: '9'}}>
                        <Navbar/>
                    </Nav>
                </div>                  
                <div className="d-flex bottomline position-fixed w-100">
                    <div className="bg-danger d-flex align-items-center justify-content-center text-white text-center w-100 px-3 py-2">
                        <div className="bg-agent me-2"></div>
                        <p className="text-white">Agent Login</p>
                    </div>
                    <div className="bg-dark d-flex align-items-center justify-content-center text-white text-center w-100 px-3 py-2">
                        <div className="d-flex me-3">
                            <a href={window.sessionStorage.getItem("VM_APP_FB_LINK")} target="_blank" rel="noopener noreferrer">
                                <div className="bg-facebook"></div>
                            </a>
                            {/* <a href={window.sessionStorage.getItem("VM_APP_TWITTER_LINK} target="_blank" rel="noopener noreferrer">
                                <div className="bg-twitter mx-2"></div>
                            </a> */}
                            <a href={window.sessionStorage.getItem("VM_APP_INSTA_LINK")} target="_blank" rel="noopener noreferrer">
                                <div className="bg-instagram mx-2"></div>
                            </a>
                        </div>
                    </div>
                </div>
                <Container>
                    <div className="d-flex justify-content-between pt-3">
                        <Image src={MainLogo} alt={window.sessionStorage.getItem("VM_APP_AGENT_NAME")}/>
                        <h3 className="menuclose text-white position-fixed me-4 mt-4" id="menu_close" onClick={closenav}>&#10006;</h3>
                        <p className="text-white h1 mt-4 me-4" id="menu_open" onClick={openmenu}>&#9776;</p>
                    </div>
                </Container>
            </header>
            <header className="header_mobile position-relative w-100 py-2">   
                <Nav class="position-fixed" id="menu_mob" style={{opacity: '0', transform: 'scaleY(0)', zIndex: '9'}}>
                    <Navbar/>
                </Nav>                                    
                
                <div className="d-flex bottomline position-fixed w-100">
                    <div className="bg-danger d-flex align-items-center justify-content-center text-white text-center w-100 px-3 py-2">
                        <div className="bg-agent me-2"></div>
                        <p className="text-white">Agent Login</p>
                    </div>
                    <div className="bg-dark d-flex align-items-center justify-content-center text-white text-center w-100 px-3 py-2">
                        <div className="d-flex me-3">
                            <a href={window.sessionStorage.getItem("VM_APP_FB_LINK")} target="_blank" rel="noopener noreferrer">
                                <div className="bg-facebook"></div>
                            </a>
                            <a href={window.sessionStorage.getItem("VM_APP_FB_LINK")} target="_blank" rel="noopener noreferrer">
                                <div className="bg-twitter mx-2"></div>
                            </a>
                            <a href={window.sessionStorage.getItem("VM_APP_FB_LINK")} target="_blank" rel="noopener noreferrer">
                                <div className="bg-instagram"></div>
                            </a>
                        </div>
                    </div>
                </div>
                <Container>
                    <div className="d-flex justify-content-between pt-3">
                        <Image src={MainLogo} alt={window.sessionStorage.getItem("VM_APP_AGENT_NAME")}/>
                        <h3 className="menuclose text-white position-fixed me-4 mt-4" id="menu_close_mob" onClick={closenav_mob}>&#10006;</h3>
                        <p className="text-white h1 mt-4 me-4" id="menu_open_mob" onClick={openmenu_mob}>&#9776;</p>
                    </div>
                </Container>
            </header>
        </div>
    );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(Header);
