import React from "react";
// import {  useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import IconButton from "@mui/material/IconButton";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Tooltip from "@mui/material/Tooltip";

// import NoImage from "./../assets/img/no-image.png";
// import NoImage from "./../assets/img/featured_area.webp";

function FeaturedCard({ item, closeBtn, goTo }) {
  // let history = useHistory();

  return (
    <div class="card shadow" style={{ borderRadius: 0, width: 250 }}>
      {/*<img
          src={`${item.main_img ? item.main_img : NoImage}`}
          class="card-img-top"
          alt="..."
        />*/}
      <div className="text-right">
        <Tooltip title="Close">
          <IconButton
            color="error"
            aria-label="add to list"
            component="span"
            onClick={() => closeBtn()}
          >
            <DangerousIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className="bg-white px-3 py-4 h-100"  onClick={() => goTo(item)}>
            <div className="d-flex justify-content-between">
                <h3 className="vm_font_bold"><NumberFormat value={item.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>
                <div className="bg-logo_property_list"></div>
            </div>
            <hr className="my-2"/>
            <p className="mb-0">MLS®{item.Ml_num}</p>
            <p className="mb-0">{item.property_type}</p>
            <p className="mb-0">{item.Addr}</p>
            <p className="mb-0">{item.Municipality}</p>
            <hr className="my-2"/>
            <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                <div className="d-flex">
                    <div className="bg-property_bed icon">
                        <div className="count vm_bg_primary text-white rounded-circle text-center">{item.bed_rooms}</div>
                    </div>
                    <div className="bg-property_bath ms-4 icon">
                        <div className="count vm_bg_primary text-white rounded-circle text-center">{item.bath_rooms}</div>
                    </div>
                    <div className="bg-property_park ms-4 icon">
                        <div className="count vm_bg_primary text-white rounded-circle text-center">{item.park_spcs}</div>
                    </div>
                </div>
                <div>&#10095;</div>
            </div>
        </div>
    </div>
  );
}

export default React.memo(FeaturedCard);
