import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";

import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";

import MainBanner from '../assets/img/innerbanner6.webp';
import Img1 from '../assets/img/solcity_development.webp';
const TITLE = process.env.REACT_APP_SITE_NAME+' - Development'

const Development = () => {
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <HeaderComponent />
            <section>
                    {/* <div className="innerbanner w-100" style={{background: "url({ banner })"}}></div> */}
                    <Image className="innerbanner w-100" src={MainBanner} />
                </section>

                {/* <div className="menubackdrop"></div> */}

                <section className="py-5">
                    <Container className="py-5">
                        <h1 className="text-center text-uppercase">Development</h1>
                        <p class="text-center pt-2">The study of real estate development is importance to every individual. In recent years, the field of real estate investments has seen a variety of new opportunities and philosophies. The real estate investment has been very “hot” since 2000 and gives every indication of remaining so throughout at least the middle of the decade. As a result of government tax and monetary policies, real estate development valuation provides excellent riskreturn opportunities that enable many individuals to build a secure foundation for their wealth. In addition, most highincome individuals are over invested in the stock market. The portfolio of these individuals would benefit from diversification into commercial real estate. Unlike equity securities, commercial real estate often generates a substantial and predictable cash flow over time. The compounding effect of this cash flow can significantly enhance the performance of most investment portfolios.</p>
                        <Image className="w-100 mt-5" src={Img1}/>
                    </Container>
                </section>             
                
            <FooterComponent />
        </>
    );
};
export default Development;