import React from "react";
import {Container, Row, Col} from 'react-bootstrap'
import ImpLinksBg from './../../../assets/img/agent_important_links_bg.webp'
import { Link } from "react-router-dom";

class Importantlinks extends React.Component {
    render() {
        return (
            <div className="bg-dark vm_bg_cover" style={{ background: `url(${ImpLinksBg})` }}>
                <div className="vm_overlay_dark_7 h-100 w-100 p-5">
                    <Container className="py-5">
                        <h2 align="center" className="text-white text-uppercase vm_fm_secondary fm_font_bold pb-5">Online Real Estate Tools</h2>

                        <Row>
                            <Col md={3}>
                                <div className="d-flex justify-content-center">
                                    <div className="bg-property_serach mb-3"></div>
                                </div>
                                <h4 align="center" className="text-white vm_font_bold">Property Search</h4>
                                <p align="center" className="text-white pt-1">Search Like a REALTOR using the most
advanced listing search on the web - updated daily.</p>
                            </Col>
                            <Col md={3} className="px-4">
                                <div className="d-flex justify-content-center">
                                    <div className="bg-alert_alert mb-3"></div>
                                </div>
                                <h4 align="center" className="text-white vm_font_bold">Alert Alert</h4>
                                <p align="center" className="text-white pt-1">Get notified instantly when homes in your neighourhood hit the market!</p>
                            </Col>
                            <Col md={3} className="px-4">
                                <div className="d-flex justify-content-center">
                                    <div className="bg-market_news mb-3"></div>
                                </div>
                                <h4 align="center" className="text-white vm_font_bold">Market News</h4>
                                <p align="center" className="text-white pt-1">Get informed with what’s going on in your local real estate market!</p>
                            </Col>
                            <Col md={3}>
                                <div className="d-flex justify-content-center">
                                    <div className="bg-mortgage_rate mb-3"></div>
                                </div>
                                <h4 align="center" className="text-white vm_font_bold">Mortgage Rates</h4>
                                <p align="center" className="text-white pt-1">Shopping for a mortgage? Browse dozens of real-time mortgage rates here!</p>
                            </Col>
                        </Row>

                        <h2 align="center" className="text-white text-uppercase vm_fm_secondary fm_font_bold mt-4 pb-5 pt-5">Real Estate Calculators</h2>

                        <Row>
                            <Col md={3}>
                            <Link to="/mortgageloan"><div className="d-flex justify-content-center">
                                    <div className="bg-mortgage mb-3"></div>
                                </div>
                                <h4 align="center" className="text-white vm_font_bold">Mortgage</h4>
                                <p align="center" className="text-white pt-1">Calculator</p></Link>
                            </Col>
                            <Col md={3}>
                            <Link to="/cmhcpremium">
                                <div className="d-flex justify-content-center">
                                    <div className="bg-cmhc_insurance mb-3"></div>
                                </div>
                                <h4 align="center" className="text-white vm_font_bold">CMHC Insurance</h4>
                                <p align="center" className="text-white pt-1">Calculator</p></Link>
                            </Col>
                            <Col md={3}>
                            <Link to="/landtransfer"><div className="d-flex justify-content-center">
                                    <div className="bg-land_transfer_tax mb-3"></div>
                                </div>
                                <h4 align="center" className="text-white vm_font_bold">Land Transfer Tax</h4>
                                <p align="center" className="text-white pt-1">Calculator</p></Link>
                            </Col>
                            <Col md={3}>
                            <Link to="/mortgageaffordability"><div className="d-flex justify-content-center">
                                    <div className="bg-affordability mb-3"></div>
                                </div>
                                <h4 align="center" className="text-white vm_font_bold">Affordability</h4>
                                <p align="center" className="text-white pt-1">Calculator</p></Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default Importantlinks