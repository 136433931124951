import React from "react";
import { Helmet } from "react-helmet";
import './../assets/css/homeworth.css'
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import HomeWorthForm from "../componets/Forms/HomeWorthForm";

const TITLE = process.env.REACT_APP_SITE_NAME+' - Home Value Estimator'

const HomeWorth1 = () => {
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <HeaderComponent />
            <div className="menubackdrop"></div>

            <section className="homeworth">
                <div className="homeworth_1 py-5">
                    <Container className="py-5">
                        <Row>
                            <Col md={6}>
                                <h2 className="vm_font_bold text-center text-uppercase pt-5">Find out what your<br/>home is really worth</h2>
                                <p className="text-center pt-3 pb-4">Get instant VIP email alerts for recently reduced listings in any area!</p>
                                <HomeWorthForm formName="Home Value Estimator" pageName="Home Value Estimator" btnName="Get Estimate" showUnit="1"></HomeWorthForm>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>   
            <FooterComponent />
        </>
    );
};
export default HomeWorth1;