import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import './../assets/css/pricedropalert.css'
import HomeWorthForm from "../componets/Forms/HomeWorthForm";

const TITLE = process.env.REACT_APP_SITE_NAME+' - Price Drop Alert'

const PriceDropAlert1 = () => {
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <HeaderComponent />
            <div className="menubackdrop"></div>

            <section className="pricedropalert">
                <div className="pricedropalert_1">
                    <div className="overlay py-5">
                        <Container className="py-5">
                            <Row className="d-flex justify-content-center">
                                <Col md={8} className="py-5">
                                    <h2 className="vm_font_bold text-center text-uppercase text-white">Know when your dream home drops in price</h2>
                                    <p className="text-center pt-3 pb-4 text-white">Get instant VIP email alerts for recently reduced listings in any area!</p>
                                    <HomeWorthForm formName="Price Drop Alert" pageName="Price Drop Alert" btnName="Submit" showUnit="0"></HomeWorthForm>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>         
            <FooterComponent />
        </>
    );
};
export default PriceDropAlert1;