function LoadingButton({ style, title, loading, handleClick, type }) {
    return (

        <button
            className={`${style}  `} 
            disabled={loading}
            onClick={handleClick}
            type={!type ? "button" : type}
        >
            {loading ? (
                <div className="px-3">
                    <div
                        className="spinner-border spinner-border-sm text-dark"
                        role="status"
                    >
                        {" "}
                    </div>
                </div>
            ) : (
                title
            )}
        </button>

    )
}

export default LoadingButton