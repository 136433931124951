import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import Swal from "sweetalert2";
import moment from "moment";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import TextInput from "../inputs/TextInput";
import SelectInput from "../inputs/SelectInput";
import Divider from "@mui/material/Divider";
import Switch from "../switch/Switch";

import EnquiryModel from "../../../../Models/EnquiryModel";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "unset",
}));

const inputStyle = {
  border: "1px solid #ccc",
  padding: 10,
  marginBottom: 10,
  borderRadius: 5,
  transition: "opacity 1s ease-in",
};

export default function EnquiryForm(props) {
  console.log("props ", props);

  const [loading, setLoading] = useState(false);

  const sendReply = async (payload) => {
    setLoading(true);

    // return;

    try {
      await EnquiryModel.reply({ ...payload, type: "reply" });
      props.fetchEnquiries();
      Swal.fire("Done", "Successfully replied", "success");
      setLoading(false);
      props.closeModal();
    } catch (error) {
      setLoading(false);
      Swal.fire("error", "Something went wrong.", "error");
    }
  };

  const formik = useFormik({
    initialValues: {
      subject: get("", "subject", ""),
      msg: get("", "msg", ""),
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("This field is requried"),
      msg: Yup.string().required("This field is requried"),
    }),
    onSubmit: (values) => {
      //       console.log("Values => ", values);
      // console.log(props);
      //       return;
      sendReply({
        email_address: props.initialValues.email_address,
        full_name: props.initialValues.full_name,
        subject: values.subject,
        msg: values.msg,
        enquiry_id: props.initialValues.id,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div class="form-group">
        <label for="subject">Email Subject</label>
        <input
          type="text"
          class="form-control"
          id="subject"
          aria-describedby="subjectHelp"
          value={formik.values.subject}
          onChange={(value) =>
            formik.setFieldValue("subject", value.target.value)
          }
          onBlur={() => formik.setFieldTouched("subject")}
        />
        {Boolean(formik.errors.subject) && formik.touched.subject ? (
          <small
            id="subjectError"
            class={
              "form-text " + Boolean(formik.errors.subject)
                ? "text-danger"
                : "text-muted"
            }
          >
            {formik.errors.subject}
          </small>
        ) : (
          ""
        )}
      </div>
      <div class="form-group">
        <label for="emailMessage">Message</label>
        <textarea
          type="text"
          class="form-control"
          id="emailMessage"
          rows="5"
          value={formik.values.msg}
          onChange={(value) => formik.setFieldValue("msg", value.target.value)}
          onBlur={() => formik.setFieldTouched("msg")}
        />
        {Boolean(formik.errors.msg) && formik.touched.msg ? (
          <small
            id="msgError"
            class={
              "form-text " + Boolean(formik.errors.msg)
                ? "text-danger"
                : "text-muted"
            }
          >
            {formik.errors.msg}
          </small>
        ) : (
          ""
        )}
      </div>

      <button type="submit" class="btn btn-primary" disabled={loading}>
        {!loading ? (
          "Send"
        ) : (
          <div className="px-3">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        )}
      </button>
    </form>
  );
}
