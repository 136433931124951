import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import './../assets/css/pricedropalert.css'
import PropertyListImg1 from './../assets/img/propimg1.webp'
import PropertyListImg2 from './../assets/img/propimg2.webp'

const TITLE = 'Price Drop Alert'

const PriceDropAlert1 = () => {
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <HeaderComponent />
            <div className="menubackdrop"></div>

            <section className="pricedropalert bg-dark">
                <Container className="pricedropalert_3 position-relative">
                    <div className="d-flex">
                        <div className="col">
                            <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20541.608115158913!2d-79.46017664420611!3d43.74904574917488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2slk!4v1664204259916!5m2!1sen!2slk" width="100%" height="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col p-4 property_list">
                            <Row>
                                <Col md={6} className="pb-4">
                                    <div className="item">
                                        <div className="overlay d-flex justify-content-center align-items-center">
                                            <Image className="img w-100" src={PropertyListImg2} />
                                            <div className="border text-white px-3 py-2 position-absolute readmore">View More</div>
                                        </div>
                                        <div className="bg-white p-3">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h4 className="vm_font_bold">Toronto, On</h4>
                                                <p className="border vm_font_bold px-3 py-0">$ 5,989,800</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="bg-location_small_black me-2"></div>
                                                <p>353 Oakwood Avenue</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="pb-4">
                                    <div className="item">
                                        <div className="overlay d-flex justify-content-center align-items-center">
                                            <Image className="img w-100" src={PropertyListImg1} />
                                            <div className="border text-white px-3 py-2 position-absolute readmore">View More</div>
                                        </div>
                                        <div className="bg-white p-3">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h4 className="vm_font_bold">Toronto, On</h4>
                                                <p className="border vm_font_bold px-3 py-0">$ 5,989,800</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="bg-location_small_black me-2"></div>
                                                <p>353 Oakwood Avenue</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="pb-4">
                                    <div className="item">
                                        <div className="overlay d-flex justify-content-center align-items-center">
                                            <Image className="img w-100" src={PropertyListImg2} />
                                            <div className="border text-white px-3 py-2 position-absolute readmore">View More</div>
                                        </div>
                                        <div className="bg-white p-3">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h4 className="vm_font_bold">Toronto, On</h4>
                                                <p className="border vm_font_bold px-3 py-0">$ 5,989,800</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="bg-location_small_black me-2"></div>
                                                <p>353 Oakwood Avenue</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="pb-4">
                                    <div className="item">
                                        <div className="overlay d-flex justify-content-center align-items-center">
                                            <Image className="img w-100" src={PropertyListImg1} />
                                            <div className="border text-white px-3 py-2 position-absolute readmore">View More</div>
                                        </div>
                                        <div className="bg-white p-3">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h4 className="vm_font_bold">Toronto, On</h4>
                                                <p className="border vm_font_bold px-3 py-0">$ 5,989,800</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="bg-location_small_black me-2"></div>
                                                <p>353 Oakwood Avenue</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </section>      
               
            <FooterComponent />
        </>
    );
};
export default PriceDropAlert1;