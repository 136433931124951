import React, { useState, useEffect } from "react";
import axios from "./../../common/ajax";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { useParams,useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Helmet } from "react-helmet";
import { Container,Col,Tabs, Tab,Row } from "react-bootstrap";

import SearchModel from "../../Models/SearchModel";
import SettingModel from "../admin/models/SettingModel";
import { savePropertyTypes,saveSearchData } from "../../store/actions/Search/SearchActions";
import { setShowings } from "../../store/actions/App/AppActions";

import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";

import FeaturedCard from "../../componets/Cards/FeaturedCard";

const SearchResultsPage = (props) => {
  const location = useLocation();
  const { buildingType } = useParams();
  const [cnt, setCnt] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [requestData, setRequestData] = useState({ next_page_url: null });
  // const [tempSearchData, setTempSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(false);
 
  useEffect(() => {
    if(buildingType && buildingType != ''){
      props.searchState.comBuildingType=buildingType;
    }
    setLoading(true);

    if (props.searchState.results.length > 0) {
        //console.log(`hmm => `,);
        if (props.searchState.results.length > 1) {

            //console.log(`more than 1 => `,);
            setSearchData(props.searchState.results);
            setRequestData({
            next_page_url: props.searchState.results.next_page_url,
            });
            setLoading(false);

        }
        return;
    }

    if(props.searchState.searchData.propertyType != 'RESEDENTIAL'){    
        props.searchState.searchData={
            propertyType: "RESEDENTIAL",
            buildingType: "any",
            transactionType: "any",
            keyword: "",
            minPrice: 0,
            maxPrice: 0,
            bed: "any",
            bath: "any",    
            parking: "any",
        };
    }

    //console.log(`coming? => `,);
    fetchProps();
  }, [buildingType]);

  // search
    const [search, setSearch] = useState(props.searchState.searchData);   
    const fetchProps = async () => {
        if(props.searchState.searchData.propertyType == 'RESEDENTIAL'){
            try {
                let _search = props.searchState.searchData;
                if(buildingType && buildingType != ''){
                  _search.buildingType=buildingType;
                  search.buildingType=buildingType;
                }

                let res = await SearchModel.search(_search);
                if (res.results.length !== undefined && res.results.length > 0) {
                    setSearchData(res.results);
                    setCnt(res.cnt);
                    setSearching(false);
                    setLoading(false);

                    return;
                } else {
                    setSearchData([]);
                }
                setSearchData(res.results.data);
                setCnt(res.cnt);
                setRequestData({ next_page_url: res.results.next_page_url });
                setLoading(false);
                setSearching(false);
            } catch (error) {
                console.log("error =>", error);
            }
        }
    };

  const [suggestions, setSuggestions] = useState([]);
  const [searching, setSearching] = useState(false);

  // find suggesstion only if user type any keyword
  useEffect(() => {
    if(props.searchState.searchData.propertyType == 'RESEDENTIAL'){
        if(search.keyword && search.keyword != ''){
            SearchModel.findSimilarity(search.keyword).then((res) => {
            // matching rating is greater than 0
            if (res.bestMatch.rating > 0) {
                // copy array without duplication
                let data = cloneDeep(suggestions);
                const allInarray = data.filter(
                (values) => values === res.bestMatch.target
                );
                // Make sure the value doesn't exist already
                if (allInarray.length === 0) {
                data.push(res.bestMatch.target);
                setSuggestions(data);
                }
            }
            });
        }
    }
  }, [search.keyword]);

  // let history = useHistory();
  async function handleFormSubmit(event) {
    event.preventDefault();

    // if (search.keyword === "") {
    //   Swal.fire("Error", "Search keyword cannot be empty", "error");
    //   return;
    // }

    setSearching(true);
    let res = await SearchModel.search(search);
    //console.log(res);
    search.propertyType="RESEDENTIAL";
    props.saveSearchData(search);

    // MLS search only
    if (res !== undefined && res.length > 0) {
      setSearchData(res);
      setCnt(res.cnt);
      setSearching(false);

      return;
    } else {
      setSearchData([]);
    }

    // other searches
    if (res.results.data.length > 0) {
      setSearchData(res.results.data);
      setCnt(res.cnt);
    } else {
      setSearchData([]);
    }

    setSearching(false);
  }
 
  const [suggestionClicked, setSuggestionClicked] = useState(true);

  const handleTyping = (value) => {      
    setSearch({ ...search, keyword: value });    
    if (suggestionClicked) {
      setSuggestionClicked(false);
    }
  };

  const handleSuggestionClick = (keyword) => {       
    setSearch({ ...search, keyword: keyword });    
    setSuggestions([]);
    setSuggestionClicked(true);
    setCount(true);
  };

  const fetchMore = async () => {
    try {
      if (requestData.next_page_url) {
        let _temp = cloneDeep(searchData);

        const res = await axios.post(requestData.next_page_url, search);

        //console.log(`res => `, res);

        _temp.push(...res.data.results.data);

        setSearchData([..._temp]);
        // setTempSearchData([...searchData, res.data.results.data]);

        // this is for lazy loading
        setRequestData(res.data.results);

        // setRequestData({next_page_url: res.data.results.next_page_url})

        setSearching(false);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleOnBlur = () => {
    setTimeout(function () {
      setSuggestionClicked(true);
      setCount(count + 1);
    }, 200);
  };

  function addToShowings(payload) {
    let storage = JSON.parse(localStorage.getItem("showings"));
    const found = storage.find((pr) => pr.property_id === payload.property_id);

    if (found !== undefined || found) {
      Swal.fire(
        "Already Exist",
        "Property already in showings list.",
        "warning"
      );
      return;
    }
    // return;
    props.setShowings(payload);

    Swal.fire("Success", "Property added to showings list.", "success");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  var searchText='';
  if(search.propertyType=="RESEDENTIAL"){
  if(search.keyword && search.keyword != ''){
    searchText+=search.keyword;
  } if(search.buildingType && search.buildingType != '' && search.buildingType != 'any' && search.buildingType != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.buildingType;
  } if(search.transactionType && search.transactionType != '' && search.transactionType != 'any' && search.transactionType != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.transactionType;
  }if(search.minPrice && search.minPrice != '' && search.minPrice != 'any' && search.minPrice != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+='Greater than : $'+search.minPrice;
  }if(search.maxPrice && search.maxPrice != '' && search.maxPrice != 'any' && search.maxPrice != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+='Less than : $'+search.maxPrice;
  }if(search.bed && search.bed != '' && search.bed != 'any' && search.bed != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.bed+' Bed(s)';
  }if(search.bath && search.bath != '' && search.bath != 'any' && search.bath != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.bath+' Bath(s)';
  }if(search.parking && search.parking != '' && search.parking != 'any' && search.parking != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.parking+' Parking(s)';
  }
  if(searchText != ''){searchText='for '+searchText;}
  }
  const TITLE = process.env.REACT_APP_SITE_NAME+' - Residential Properties'

  return (
    <>
      <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />
        <div className="menubackdrop"></div>   
        <div className="bg-dark">
          <Container className="py-5 bg-dark">
            <form onSubmit={handleFormSubmit}>
                <Row>
                    <Col md={8} className="pb-3">
                        <p class="text-white">Search by MLS #, City, Neighborhood, Zip or Addres</p>
                        <input type="text" placeholder="MLS #, City, Neighborhood, Zip or Addres" name="keyword" className="form-control rounded-0" onChange={(e) => handleTyping(e.target.value)} value={search.keyword} onBlur={() => handleOnBlur()}/>
                        <ul className="list-group">
                          {!suggestionClicked &&
                          suggestions.map((suggestion, index) => (
                              <li
                              key={index}
                              className="list-group-item"
                              onClick={() => handleSuggestionClick(suggestion)}
                              >
                              {suggestion}
                              </li>
                          ))}
                      </ul>
                    </Col>
                    <Col md={2} xs={6} className="pb-3">
                        <p class="text-white">Property Type</p>
                        <select
                          className="form-select rounded-0"
                          name="buildingType"
                          id="com-building-type"
                          value={search.buildingType}
                          onChange={(e) =>
                              setSearch({ ...search, buildingType: e.target.value })
                          }
                          >
                          <option value="any">Any</option>
                          <option value="condo">Condo</option>
                          <option value="Att/Row/Twnhouse">Att/Row/Twnhouse</option>
                          <option value="Cottage">Cottage</option>
                          <option value="Detached">Detached</option>
                          <option value="Duplex">Duplex</option>
                          <option value="Farm">Farm</option>
                          <option value="Fourplex">Fourplex</option>
                          <option value="Link">Link</option>
                          <option value="Lower Level">Lower Level</option>
                          <option value="Mobile/Trailer">Mobile/Trailer</option>
                          <option value="Multiplex">Multiplex</option>
                          <option value="Other">Other</option>
                          <option value="Rural Resid">Rural Resid</option>
                          <option value="Semi-Detached">Semi-Detached</option>
                          <option value="Store W/Apt/Offc">Store W/Apt/Offc</option>
                          <option value="Triplex">Triplex</option>
                          <option value="Upper Level">Upper Level</option>
                          <option value="Vacant Land">Vacant Land</option>		
                      </select>
                    </Col>
                    <Col md={2} xs={6} className="pb-3">
                        <p class="text-white">Transaction Type</p>
                        <select
                            name="transactionType"
                            id="com-transaction-type"
                            value={search.transactionType}
                            className="form-select rounded-0"
                            onChange={(e) =>
                                setSearch({ ...search, transactionType: e.target.value })
                            }
                            >
                            <option value="any">Any</option>
                            <option value="Sale">Sale</option>
                            <option value="Lease">Rent/Lease</option>
                            <option value="Sub-Lease">Sub Lease</option>
                        </select>
                    </Col>
                    <Col md={2} xs={6} className="pb-3">
                        <p class="text-white">Min Price</p>
                        <input type="number" name="minPrice" className="form-control rounded-0" placeholder="Min Price" min="0" value={search.minPrice} step="50" onChange={(e) =>setSearch({ ...search, minPrice: e.target.value })}/>
                    </Col>
                    <Col md={2} xs={6} className="pb-3">
                        <p class="text-white">Max Price</p>
                        <input type="number" name="maxPrice" className="form-control rounded-0" placeholder="Min Price" min="0" value={search.maxPrice} step="50" onChange={(e) =>setSearch({ ...search, maxPrice: e.target.value })}/>
                    </Col>
                    <Col md={2} xs={6} className="pb-3">
                        <p class="text-white">Bed</p>
                        <select
                          name="bed"
                          id="com-bed"
                          value={search.bed}
                          className="form-select rounded-0"
                          onChange={(e) =>
                          setSearch({ ...search, bed: e.target.value })
                          }
                      >
                          <option value="any">Any</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                      </select>
                    </Col>
                    <Col md={2} xs={6} className="pb-3">
                        <p class="text-white">Bath</p>
                        <select
                          name="bath"
                          id="com-bath"
                          value={search.bath}
                          className="form-select rounded-0"
                          onChange={(e) =>
                          setSearch({ ...search, bath: e.target.value })
                          }
                      >
                          <option value="any">Any</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                      </select>
                    </Col>
                    <Col md={2} xs={6} className="pb-3">
                        <p class="text-white">Parking</p>
                        <select
                        name="parking"
                        id="com-parking"
                        value={search.parking}
                        className="form-select rounded-0"
                        onChange={(e) =>
                            setSearch({ ...search, parking: e.target.value })
                        }
                        >
                        <option value="any">Any</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        </select>
                    </Col>
                    <Col md={2} xs={6} className="pb-3 d-flex align-items-end">
                        <input type="submit" name="keyword" class="form-control btn_dark_black" value="Search"/>
                    </Col>
                </Row>
            </form>
          </Container>
        </div>     
        <Container className="">  
          <section className="py-5">
                <h2 className="text-center text-uppercase vm_font_bold">Residential Property</h2>
                <p className="text-center pt-2">Find your next residential property with Canada's largest real estate website</p>
                <div className="d-flex justify-content-center pt-2">
                </div>
                
                <h4 className="mt-5 text-center">
                {loading ? "Searching" : "Search"} {!loading && "Results"} {" "}
                {searchText} {loading && "..."}
                {loading ? "" : "("+cnt+")"}
                </h4>
                {searching && (
                  <div className="d-flex justify-content-center align-items-center text-dark pb-3">
                    <h5 className="text-dark me-2">Searching please wait</h5>
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}
          </section>   
          {!loading &&
            <InfiniteScroll               
                dataLength={searchData.length} //This is important field to render the next data
                next={fetchMore}
                hasMore={requestData.next_page_url ? true : false}
                loader={
                  <div className="text-center my-3">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </div>
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
              {!loading  &&
                <div className="propoerty_tab">                   
                    <div className="bg-light">
                      <Row className="d-flex justify-content-center">
                        {searchData.length > 0 ? (
                          searchData.map((item, index) => {
                            return (
                                <Col lg={3} md={6} className="pb-4" key={index}>
                                  <FeaturedCard key={index} item={item} 
                                  addToShowings={(value) => addToShowings(value)}/>
                              </Col>                                        
                            ); //
                          })
                        ) : (
                          <h3 className="text-center">Data not found</h3>
                        )}
                      </Row>
                  </div>
              </div>
              }
            </InfiniteScroll>  
          }
          </Container>
        <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { searchState: state.searchState };
};

const mapDispatchToProps = (dispatch) => ({
  savePropertyTypes: (data) => {
    dispatch(savePropertyTypes(data));
  },
  setShowings: (data) => {
    dispatch(setShowings(data));
  },
  saveSearchData: (data) => {
    dispatch(saveSearchData(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsPage);
