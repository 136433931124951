import {
  SAVE_SEARCH_DATA,
  SAVE_SEARCH_RESULTS,
  SAVE_FEATURED_LISTINGS,
  SAVE_PROPERTY_TYPES,
} from "../actions/Search/SearchActionTypes";

const initialState = {
  searchData: {
    keyword: "Toronto",  
    propertyType: "RESEDENTIAL",
    buildingType: "any",
    transactionType: "any",
    minPrice: 0,
    maxPrice: 0,
    bed: "any",
    bath: "any",    
    parking: "any",
  },
  results: [],
  featuredListings: [],
  propertyTypes: [],
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload,
      };
    case SAVE_SEARCH_RESULTS:
      return {
        ...state,
        results: action.payload,
      };
    case SAVE_FEATURED_LISTINGS:
      // console.log(`aaa => `);
      return {
        ...state,
        featuredListings: action.payload,
      };
    case SAVE_PROPERTY_TYPES:
      return {
        ...state,
        propertyTypes: [...state.propertyTypes, action.payload],
      };
    default: {
      return state;
    }
  }
};
export default SearchReducer;
