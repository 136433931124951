import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import './../assets/css/pricedropalert.css'


const TITLE = 'Price Drop Alert'

const PriceDropAlert1 = () => {
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <HeaderComponent />
            <div className="menubackdrop"></div>

            <section className="pricedropalert">
                <div className="pricedropalert_2 d-flex position-relative">
                    <div className="d-flex justify-content-between col">
                    <iframe className="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20541.608115158913!2d-79.46017664420611!3d43.74904574917488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2slk!4v1664204259916!5m2!1sen!2slk" width="100%" height=""  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="bg-dark form w-100 h-100 col d-flex align-items-center">
                        <div className="p-5">
                            <h2 className="text-white">Create Account</h2>
                            <p className="text-white pb-4">Get instant VIP email alerts for recently reduced listings in any area!</p>
                            <form action="/pricedropalert3">
                                <input className="form-control bg-dark rounded-0 border border-1 mb-4" type="text" name="yourname" placeholder="Your Name"/>
                                <input className="form-control bg-dark rounded-0 border border-1 mb-4" type="email" name="email" placeholder="Email Address"/>
                                <input className="form-control bg-dark rounded-0 border border-1" type="phone" name="phone" placeholder="Phone Number"/>
                                <button className="vm_bg_secondary text-white rounded-0 border-0 px-2 py-1 mt-4">Get Access Now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>          
                    
            <FooterComponent />
        </>
    );
};
export default PriceDropAlert1;