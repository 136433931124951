import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import moment from "moment";

export default function BasicDateTimePicker({ value, getDateAndTime }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        renderInput={(props) => <TextField {...props} />}
        label="Date and Time"
        value={value}
        onChange={(newValue) => {
          getDateAndTime(moment(newValue).format("YYYY-MM-DD, h:mm"));
        }}
        allowSameDateSelection={false}
      />
    </LocalizationProvider>
  );
}
