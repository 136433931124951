import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";

import Swal from "sweetalert2";

import RootElement from "../components/RootElement";
import SettingsCard from "../components/Cards/SettingsCard";
import SettingsCardItem from "../components/Cards/SettingsCardItem";

import MunicipalityForm from "../components/forms/MunicipalityForm";
import PropertyForm from "../components/forms/PropertyForm";
import ListingTypeForm from "../components/forms/ListingTypeForm";

import SettingModel from "../models/SettingModel";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 800,
	bgcolor: "background.paper",
	border: "1px solid #dedede",
	boxShadow: 24,
	p: 4,
};

function SkeletonLoader() {
	return (
		<div>
			<div className="d-flex align-items-center mt-4">
				<Skeleton variant="rectangular" width="90%" height={25} />
				<Skeleton
					variant="circular"
					height={25}
					width={25}
					className="ms-3"
				/>
			</div>
			<div className="d-flex align-items-center my-3">
				<Skeleton variant="rectangular" width="90%" height={25} />
				<Skeleton
					variant="circular"
					height={25}
					width={25}
					className="ms-3"
				/>
			</div>
			<div className="d-flex align-items-center">
				<Skeleton variant="rectangular" width="90%" height={25} />
				<Skeleton
					variant="circular"
					height={25}
					width={25}
					className="ms-3"
				/>
			</div>
		</div>
	);
}

const confirm = () => {
	return new Promise((resolve) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				resolve();
			}
		});
	});
};

export default function Settings(props) {
	// const [loading, setLoading] = useState(false);

	const [municipality, setMunicipality] = useState({
		loading: true,
		data: [],
	});
	const [propertyTypes, setPropertyTypes] = useState({
		loading: true,
		data: [],
	});
	const [listingTypes, setListingTypes] = useState({
		loading: true,
		data: [],
	});

	// const [dense, setDense] = useState(false);

	const [open, setOpen] = useState(false);
	const [formType, setFormType] = useState("");
	const [resStatus, setResStatus] = useState(null);
	const [showMsg, setShowMsg] = useState(false);

	const handleOpen = (type) => {
		setFormType(type);
		setOpen(true);
	};

	const handleClose = (value) => {
		setResStatus(value);

		setShowMsg(true);

		setOpen(false);

		setTimeout(function () {
			setShowMsg(false);
		}, 1500);
	};

	async function fetchMunicipality() {
		setMunicipality({
			loading: true,
			data: [],
		});

		try {
			const res = await SettingModel.fetchMunicipality();
			setMunicipality({
				loading: false,
				data: res,
			});
		} catch (error) {
			console.log("error ", error);
		}
	}

	async function fetchPropertyTypes() {
		setPropertyTypes({
			loading: true,
			data: [],
		});

		try {
			const res = await SettingModel.fetchPropertyTypes();
			setPropertyTypes({
				loading: false,
				data: res,
			});
		} catch (error) {
			console.log("error ", error);
		}
	}

	async function fetchListingTypes() {
		setListingTypes({
			loading: true,
			data: [],
		});

		try {
			const res = await SettingModel.fetchListingTypes();
			setListingTypes({
				loading: false,
				data: res,
			});
		} catch (error) {
			console.log("error ", error);
		}
	}

	useEffect(() => {
		fetchMunicipality();
		fetchPropertyTypes();
		fetchListingTypes();

		return () => {
			fetchMunicipality();
			fetchPropertyTypes();
			fetchListingTypes();
		};
	}, []);

	const deleteEntry = async (payload) => {
		try {
			await confirm();

			await SettingModel.deleteEntry(payload);

			switch (payload.type) {
				case "municipality":
					// code block
					await fetchMunicipality();
					break;
				case "property":
					// code block
					await fetchPropertyTypes();
					break;
				case "listing":
					// code block
					await fetchListingTypes();
					break;
				default:
				// code block
			}

			Swal.fire("Success", "Successfully deleted", "success");
		} catch (error) {
			console.log("error => ", error);
			Swal.fire("Error", "Something went wrong", "error");
		}
	};

	return (
		<RootElement>
			{/* {loading && (
				<Box sx={{ width: "100%" }}>
					<LinearProgress />
				</Box>
			)} */}

			<div
				className="py-5"
				style={{ backgroundColor: "#fbfbfb", height: 120 + "vh" }}
			>
				<div className="row px-2">
					<div className="col-md-4 col-lg-3">
						<SettingsCard
							title="Municipality"
							btnClick={() => handleOpen("municipality")}
						>
							<div
								style={{ height: 200 }}
								className="overflow-auto"
							>
								{municipality.loading ? (
									<SkeletonLoader />
								) : (
									<div>
										{municipality.data.length > 0 ? (
											<List dense={false}>
												{municipality.data.map(
													(item, index) => (
														<SettingsCardItem
															key={index}
															label={
																item.Municipality
															}
															data={item}
															table="municipality"
															deleteEntry={(
																payload
															) =>
																deleteEntry(
																	payload
																)
															}
														/>
													)
												)}
											</List>
										) : (
											<h5 className="text-center mt-5">
												No Data Found
											</h5>
										)}
									</div>
								)}
							</div>
						</SettingsCard>
					</div>

					{/* Property Type */}
					<div className="col-md-4 col-lg-3">
						<SettingsCard
							title="Property Types"
							btnClick={() => handleOpen("propertyType")}
						>
							<div
								style={{ height: 200 }}
								className="overflow-auto"
							>
								{propertyTypes.loading ? (
									<SkeletonLoader />
								) : (
									<div>
										{propertyTypes.data.length > 0 ? (
											<List dense={false}>
												{propertyTypes.data.map(
													(item, index) => (
														<SettingsCardItem
															key={index}
															label={
																item.prop_type
															}
															data={item}
															table="property"
															deleteEntry={(
																payload
															) =>
																deleteEntry(
																	payload
																)
															}
														/>
													)
												)}
											</List>
										) : (
											<h5 className="text-center mt-5">
												No Data Found
											</h5>
										)}
									</div>
								)}
							</div>
						</SettingsCard>
					</div>

					{/* Listing Type */}
					<div className="col-md-4 col-lg-3">
						<SettingsCard
							title="Listing Types"
							btnClick={() => handleOpen("listingType")}
						>
							<div
								style={{ height: 200 }}
								className="overflow-auto"
							>
								{listingTypes.loading ? (
									<SkeletonLoader />
								) : (
									<div>
										{listingTypes.data.length > 0 ? (
											<List dense={false}>
												{listingTypes.data.map(
													(item, index) => (
														<SettingsCardItem
															key={index}
															label={item.type}
															data={item}
															table="listing"
															deleteEntry={(
																payload
															) =>
																deleteEntry(
																	payload
																)
															}
														/>
													)
												)}
											</List>
										) : (
											<h5 className="text-center mt-5">
												No Data Found
											</h5>
										)}
									</div>
								)}
							</div>
						</SettingsCard>
					</div>
				</div>
			</div>

			{/* All modals goes here */}
			<div>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={open}>
						<Box sx={style}>
							<div>
								{formType === "municipality" && (
									<MunicipalityForm
										fetchData={() => fetchMunicipality()}
										closemodal={(value) =>
											handleClose(value)
										}
									/>
								)}

								{formType === "propertyType" && (
									<PropertyForm
										fetchData={() => fetchPropertyTypes()}
										closemodal={(value) =>
											handleClose(value)
										}
									/>
								)}

								{formType === "listingType" && (
									<ListingTypeForm
										fetchData={() => fetchListingTypes()}
										closemodal={(value) =>
											handleClose(value)
										}
									/>
								)}
							</div>
						</Box>
					</Fade>
				</Modal>
			</div>

			{resStatus && showMsg && (
				<div style={{ position: "absolute", top: 10, right: 0 }}>
					<div
						class="alert alert-success alert-dismissible fade show"
						role="alert"
					>
						<strong>Successfully created</strong>
						<button
							type="button"
							class="close"
							data-dismiss="alert"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			)}

			{!resStatus && showMsg && (
				<div style={{ position: "absolute", top: 10, right: 0 }}>
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
					>
						<strong>Something went wrong</strong>
						<button
							type="button"
							class="close"
							data-dismiss="alert"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			)}
		</RootElement>
	);
}
