import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import banner from './../assets/img/innerbanner3.webp';
import buyOffice from './../assets/img/buy_office.webp';
import buyKitchen from './../assets/img/buy_kitchen.webp';
import { Link } from "react-router-dom";

const TITLE = process.env.REACT_APP_SITE_NAME+' - Buy'  

const Buy = () => {
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />      
        <div className="menubackdrop"></div>

        <section className="py-5">
          <Container className="py-4">
            <h1 className="text-center text-uppercase vm_font_bold">
            Buyer's Guide
            </h1>
            <div class="d-flex pb-2 justify-content-center">
              <div class="col-md-2">
                <hr />
              </div>
            </div>
            <p class="text-center pb-3 pb-5">
              Are you interested in buying a home or a commercial property? Our
              team at Sol City Realty can assist! Let our experienced team
              members help you find the property of your vision.
            </p>

            <Row>
              <Col md={6}>
                <Image className="w-100" src={buyKitchen} />
              </Col>
              <Col md={6}>
                <Image className="w-100" src={buyOffice} />
              </Col>
            </Row>

            <h3 className="text-center pt-5">
              Let’s find your dream property. Let’s connect.
            </h3>

            <p className="text-center pt-2">
              Sol City Realty is a team of Ontario’s real estate experts. They
              specialize in finding unique homes, cottages, condominiums and
              commercial property located in all regions in the GTA. Our team
              has the experience and dedication to help you. Clients get the
              utmost care and time from our agents, and that is guaranteed.{" "}
            </p>
            <div className="d-flex justify-content-center pt-4">
            <Link to="/contact-us">
                <div class="bg-dark text-white px-3 py-2 border-0">
                  Contact us
                </div>
              </Link>
            </div>
          </Container>
        </section>
      <FooterComponent />
    </>
  );
};

export default Buy;