import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col,Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
const TITLE = process.env.REACT_APP_SITE_NAME+' - Mortgage Affordability Calculator'  

const MortgageAffordability = () => {
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
            <script type="text/javascript" src="https://www.ratehub.ca/assets/js/widget-loader.js"/>
        </Helmet>
        <HeaderComponent />
        <div className="menubackdrop"></div>
        <section className="py-5 bg-light">
            <Container className="py-4">
                <h1 className="text-uppercase pb-4 mb-3">MORTGAGE AFFORDABILITY CALCULATOR</h1>

                <div className="widget" data-widget="calc-affordability" data-lang="en" /><div style={{textAlign: 'right'}}>  <a href="https://www.ratehub.ca/" style={{display: 'inline-block', width: '80px', marginTop: '.5em'}}><img src="https://www.ratehub.ca/assets/images/widget-logo.png" style={{width: '100%'}} alt="RateHub logo" /></a></div>
            </Container>
        </section>
      <FooterComponent />
    </>
  );
};

export default MortgageAffordability;