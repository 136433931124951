import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
// import Skeleton from "@mui/material/Skeleton";

// import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import Swal from "sweetalert2";

import RootElement from "../components/RootElement";

import ProfileModel from "../../../Models/ProfileModel";
import { Button } from "@mui/material";

function showMessage(type, message) {
  Swal.fire({
    position: "top-end",
    html: `<p class='text-left text-${type}'>` + `${message}` + `</p>`,
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
  });
}

export default function Settings() {
  const [loading, setLoading] = useState(true);

  // const [value, setValue] = useState("2");
  const [data, setData] = useState({
    profile_photo: "",
    website_logo: "",
    website_fav_icon: "",
    site_title: "",
    site_short_description: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
  });

  const [count, setCount] = useState(0);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    // setValue(newValue);
  };

  const fetchData = async () => {
    try {
      // console.log("data before =>", data);

      const res = await ProfileModel.fetchSiteSettings();

      // console.log("asas", res.data.site_title);

      setData({
        ...data,
        site_title: res.data.site_title,
        site_short_description: res.data.site_short_description,
        facebook: res.data.social_links.facebook,
        instagram: res.data.social_links.instagram,
        twitter: res.data.social_links.twitter,
        youtube: res.data.social_links.youtube,
      });
      setLoading(false);

      formik.initialValues.site_title = res.data.site_title;
      formik.initialValues.site_short_description =
        res.data.site_short_description;
      formik.initialValues.facebook = res.data.social_links.facebook;
      formik.initialValues.instagram = res.data.social_links.instagram;
      formik.initialValues.twitter = res.data.social_links.twitter;
      formik.initialValues.youtube = res.data.social_links.youtube;

      setTimeout(function () {
        setCount(count + 1);
        // console.log("data =>", data);
      }, 1000);
    } catch (error) {
      // console.log("error ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    handleChange(2);

    return () => {
      fetchData();
    };
  }, []);

  const saveData = async (payload) => {
    try {
      setLoading(true);
      await ProfileModel.saveSiteSettings(payload);
      showMessage("success", "Settings updated.");

      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      site_title: get(data.site_title, "site_title", data.site_title),
      site_short_description: get(
        data.site_short_description,
        "site_short_description",
        data.site_short_description
      ),
      facebook: get(data.facebook, "facebook", data.facebook),
      instagram: get(data.instagram, "instagram", data.instagram),
      twitter: get(data.twitter, "twitter", data.twitter),
      youtube: get(data.youtube, "youtube", data.youtube),
      favIcon: process.env.REACT_APP_API + "/storage/settings/favicon.png",
      logo: process.env.REACT_APP_API + "/storage/settings/logo.png",
      profile: process.env.REACT_APP_API + "/storage/settings/profile.png",
    },
    validationSchema: Yup.object({
      site_title: Yup.string().required("This field is requried"),
      site_short_description: Yup.string().required("This field is requried"),
    }),
    onSubmit: (values) => {
      console.log("a", values);

      const socialLinks = {
        facebook: values.facebook,
        instagram: values.instagram,
        twitter: values.twitter,
        youtube: values.youtube,
      };

      const data = {
        ...values,
        site_title: values.site_title,
        site_short_description: values.site_short_description,
        social_links: JSON.stringify(socialLinks),
      };

      saveData(data);
    },
  });

  return (
    <RootElement>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <div className="p-3">
        <div className="profile-settings card shadow mt-5 p-3">
          <div>
            <form onSubmit={formik.handleSubmit}>
              <h5>Primary Details</h5>
              <div className="form-group row">
                <div className="col-md-4">
                  <div className="d-flex justify-content-between">
                    <label
                      for="inputTitle"
                      className="col-form-label font-weight-bold"
                    >
                      Site Title
                    </label>
                    <small className="text-muted">
                      <strong>Your website title</strong>
                    </small>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    value={data.site_title}
                    onChange={(value) => {
                      setData({
                        ...data,
                        site_title: value.target.value,
                      });
                      formik.setFieldValue("site_title", value.target.value);
                    }}
                    onBlur={() => formik.setFieldTouched("site_title")}
                  />
                  {Boolean(formik.errors.site_title) &&
                  formik.touched.site_title ? (
                    <small
                      id="subjectError"
                      class={
                        "form-text " + Boolean(formik.errors.site_title)
                          ? "text-danger"
                          : "text-muted"
                      }
                    >
                      {formik.errors.site_title}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-8">
                  <div className="d-flex justify-content-between">
                    <label
                      for="shortDescription"
                      className="col-form-label font-weight-bold"
                    >
                      Short Description
                    </label>

                    <small className="text-muted">
                      <strong>Your website short description</strong>
                    </small>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="shortDescription"
                    value={data.site_short_description}
                    onChange={(value) => {
                      setData({
                        ...data,
                        site_short_description: value.target.value,
                      });
                      formik.setFieldValue(
                        "site_short_description",
                        value.target.value
                      );
                    }}
                    onBlur={() =>
                      formik.setFieldTouched("site_short_description")
                    }
                  />
                  {Boolean(formik.errors.site_short_description) &&
                  formik.touched.site_short_description ? (
                    <small
                      id="subjectError"
                      class={
                        "form-text " +
                        Boolean(formik.errors.site_short_description)
                          ? "text-danger"
                          : "text-muted"
                      }
                    >
                      {formik.errors.site_short_description}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 my-3">
                  <div className="card">
                    {formik.values.favIcon && (
                      <img
                        src={formik.values.favIcon}
                        alt=""
                        srcset=""
                        height={250}
                        width={350}
                        className="card-img-top mb-1"
                      />
                    )}

                    <br />
                    <Button variant="contained" component="label">
                      {formik.values.favIcon ? "Change" : "Upload"} Fav Icon
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                              if (fileReader.readyState === 2) {
                                formik.setFieldValue(
                                  "favIcon",
                                  fileReader.result
                                );
                              }
                            };
                            fileReader.readAsDataURL(e.target.files[0]);
                          }
                        }}
                      />
                    </Button>
                  </div>
                </div>

                <div className="col-md-3 my-3">
                  <div className="card">
                    {formik.values.logo && (
                      <img
                        src={formik.values.logo}
                        alt=""
                        srcset=""
                        height={250}
                        width={350}
                        className="card-img-top mb-1"
                      />
                    )}

                    <br />
                    <Button variant="contained" component="label">
                      {formik.values.logo ? "Change" : "Upload"} Site Logo
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                              if (fileReader.readyState === 2) {
                                formik.setFieldValue("logo", fileReader.result);
                              }
                            };
                            fileReader.readAsDataURL(e.target.files[0]);
                          }
                        }}
                      />
                    </Button>
                  </div>
                </div>

                <div className="col-md-3 my-3">
                  <div className="card">
                    {formik.values.profile && (
                      <img
                        src={formik.values.profile}
                        alt=""
                        srcset=""
                        height={250}
                        width={350}
                        className="card-img-top mb-1"
                      />
                    )}

                    <br />
                    <Button variant="contained" component="label">
                      {formik.values.profile ? "Change" : "Upload"} Profile
                      Image
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                              if (fileReader.readyState === 2) {
                                formik.setFieldValue(
                                  "profile",
                                  fileReader.result
                                );
                              }
                            };
                            fileReader.readAsDataURL(e.target.files[0]);
                          }
                        }}
                      />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <h5>Social Links</h5>
              </div>

              <div className="form-group row">
                <label for="Facebook" className="col-sm-2 col-form-label">
                  Facebook
                </label>
                <div className="col-sm-10">
                  <input
                    type="url"
                    className="form-control"
                    id="Facebook"
                    placeholder="https://www.facebook.com/pageid"
                    value={data.facebook}
                    onChange={(value) => {
                      setData({
                        ...data,
                        facebook: value.target.value,
                      });
                      formik.setFieldValue("facebook", value.target.value);
                    }}
                    onBlur={() => formik.setFieldTouched("facebook")}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label for="twitter" className="col-sm-2 col-form-label">
                  Instagram
                </label>
                <div className="col-sm-10">
                  <input
                    type="url"
                    className="form-control"
                    id="twitter"
                    placeholder="https://www.instagram.com/username"
                    value={data.instagram}
                    onChange={(value) => {
                      setData({
                        ...data,
                        instagram: value.target.value,
                      });
                      formik.setFieldValue("instagram", value.target.value);
                    }}
                    onBlur={() => formik.setFieldTouched("instagram")}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label for="twitter" className="col-sm-2 col-form-label">
                  Twitter
                </label>
                <div className="col-sm-10">
                  <input
                    type="url"
                    className="form-control"
                    id="twitter"
                    placeholder="https://www.twitter.com/username"
                    value={data.twitter}
                    onChange={(value) => {
                      setData({
                        ...data,
                        twitter: value.target.value,
                      });
                      formik.setFieldValue("twitter", value.target.value);
                    }}
                    onBlur={() => formik.setFieldTouched("twitter")}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label for="youtube" className="col-sm-2 col-form-label">
                  YouTube
                </label>
                <div className="col-sm-10">
                  <input
                    type="url"
                    className="form-control"
                    id="youtube"
                    placeholder="https://www.youtube.com/channel/110011203101"
                    value={data.youtube}
                    onChange={(value) => {
                      setData({
                        ...data,
                        youtube: value.target.value,
                      });

                      formik.setFieldValue("youtube", value.target.value);
                    }}
                    onBlur={() => formik.setFieldTouched("youtube")}
                  />
                </div>
              </div>

              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary my-3"
                  disabled={loading}
                >
                  {!loading ? (
                    "Save"
                  ) : (
                    <div className="px-3">
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </RootElement>
  );
}
