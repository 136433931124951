import React from "react";
import { Helmet } from "react-helmet";
import './../assets/css/home.css'
import './../assets/css/property_details.css'
import { Container, Row, Col, Image, Tab, Tabs } from "react-bootstrap";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { TelephoneForward, Heart, ShareFill, PinMapFill } from 'react-bootstrap-icons';
import ImageGallery from 'react-image-gallery';
import agentLogo from './../assets/img/agent_logo.webp';
import PropertyListImg1 from './../assets/img/propimg1.webp'
import PropertyListImg2 from './../assets/img/propimg2.webp'

import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";

const TITLE = 'Property Full Details'

const images = [
    {
        original: "https://vmwebs.ca/solcity/gallery/gal1.webp",
        thumbnail: "https://vmwebs.ca/solcity/gallery/thumb/thumb1.webp",
    },
    {
        original: "https://vmwebs.ca/solcity/gallery/gal2.webp",
        thumbnail: "https://vmwebs.ca/solcity/gallery/thumb/thumb2.webp",
    },
    {
        original: "https://vmwebs.ca/solcity/gallery/gal3.webp",
        thumbnail: "https://vmwebs.ca/solcity/gallery/thumb/thumb3.webp",
    },
    {
        original: "https://vmwebs.ca/solcity/gallery/gal4.webp",
        thumbnail: "https://vmwebs.ca/solcity/gallery/thumb/thumb4.webp",
    },
    {
        original: "https://vmwebs.ca/solcity/gallery/gal5.webp",
        thumbnail: "https://vmwebs.ca/solcity/gallery/thumb/thumb5.webp",
    },
    {
        original: "https://vmwebs.ca/solcity/gallery/gal6.webp",
        thumbnail: "https://vmwebs.ca/solcity/gallery/thumb/thumb6.webp",
    },
  ];
  

const PropertyDetails = () => {
   
    
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <HeaderComponent />

            <div className="menubackdrop"></div>

            <section className="py-5">
                <Container>
                    <Row>
                        <Col md={8}>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <h3 className="text-uppercase vm_font_bold">Toronto ON</h3>
                                        <h6 className="vm_bg_secondary text-white px-2 py-1 ms-2">For Sale</h6>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-location_small_black me-2"></div>
                                        <p>3142 Heritage Drive, Calgary, Alberta, Canada</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-end">
                                    {/* <div className="bg-pricetag_red mt-2"></div> */}
                                    <h2 className="vm_font_bold">$5,989,800</h2>
                                </div>
                            </div>

                            <div className="wrapper pt-3">
                                <ImageGallery 
                                items={images}  
                                infinite={true}
                                lazyLoad={true}
                                autoPlay={true}
                                autoPlay={true}
                                slideDuration={1000}

                                onSlide={() => this.onEventTrigger('onSlide')}
                                onClick={() => this.onEventTrigger('onClick')}
                                onTouchMove={() => this.onEventTrigger('onTouchMove')}

                                ref={this.myImageRef} />

                                {/* <button onClick={this.onFullscreen}>FullScreen</button>
                                <button onClick={this.onPlay}>Play</button>
                                <button onClick={this.onPause}>Pause</button>
                                <button onClick={this.onMoveToSlide}>SlideTo 3rd Slide</button> */}
                            </div>
                            <div className="border-bottom pb-3 d-flex justify-content-between pt-5">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <h5 className="vm_font_bold">3 Bed</h5>
                                        <h5 className="vm_font_bold px-3">2 Bed</h5>
                                        <h5 className="vm_font_bold">1015 sqft</h5>
                                        <h6 className="ps-3">Added 5 days ago</h6>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="border d-flex align-items-center py-0 px-2 py-1">
                                        <Heart color="black" size={11} />
                                        <h6 className="ps-2">Save</h6>
                                    </div>
                                    <div className="border d-flex align-items-center py-0 px-2 py-1 ms-3">
                                        <ShareFill color="black" size={11} />
                                        <h6 className="ps-2">Share</h6>
                                    </div>
                                    <div className="border d-flex align-items-center py-0 px-2 py-1 ms-3">
                                        <PinMapFill color="black" size={11} />
                                        <h6 className="ps-2">View on Map</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h3 className="pb-3 vm_font_bold pb-3">About the property</h3>
                                <p>Rarely offered end unit in Cornerstone Village. This open concept townhome has much to offer! The main level features a home office, laundry and convenient entry from garage. The second offers a spacious eat-in kitchen with loads of cupboard space and raised breakfast bar.</p>
                                <div className="d-flex border-top pt-4 mt-4">
                                    <div className="col-md-4">
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">Type</p>
                                            <p>: Semi Detached</p>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">Style</p>
                                            <p>: Back Split,Split Level</p>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">Size</p>
                                            <p>: 1015 sqft</p>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">Lot Size</p>
                                            <p>: 1015 sqft</p>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">Year Built</p>
                                            <p>: 1961</p>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">Taxes</p>
                                            <p>: $2,573 /yr</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">Added</p>
                                            <p>: Sep 26, 2022 (5 mins ago)</p>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">Updated</p>
                                            <p>: Sep 26, 2022 (today)</p>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">Last Checked</p>
                                            <p>: 9 minutes ago</p>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <p className="vm_font_bold pe-4">MLS®#</p>
                                            <p>: A2003169</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="ps-md-4" md={4}>
                            <div className="vm_boxshadow_1 border">
                                <p className="position-absolute bg-dark text-white px-2 py-1">Presented by</p>
                                <div className="d-flex justify-content-center align-items-center pt-5 pb-3 mt-4">
                                    <Image src={agentLogo} />
                                </div>
                                <div className="d-flex justify-content-center align-items-center pb-5">
                                    <TelephoneForward color="black" size={20} />
                                    <h4 className="ps-2">416-284-5555</h4>
                                </div>
                            </div>

                            <div className="vm_boxshadow_1 border mt-4 p-4">
                                <h4 className="text-center pb-4 vm_font_bold">Request More Information</h4>
                                <from>
                                    <input className="form-control rounded-0 mb-3" type="text" name="fullname" placeholder="Fullname"/>
                                    <input className="form-control rounded-0 mb-3" type="phone" name="phone" placeholder="Phone"/>
                                    <input className="form-control rounded-0 mb-3" type="email" name="email" placeholder="Email Address"/>
                                    <select className="form-control rounded-0 form-select mb-3">
                                        <option value="">Tell me more about this property</option>
                                    </select>
                                    <textarea className="rounded-0 form-control" row="50">I was searching on your site and came across this property. Please send me more information about this listing.Thank you.</textarea>
                                    <div className="d-flex justify-content-center">
                                        <button className="vm_bg_secondary text-white rounded-0 border-0 py-1 px-3 mt-3">Submit</button>
                                    </div>
                                </from>
                            </div>

                            <div className="pt-4">
                            <h4 className="text-center pb-4 vm_font_bold">Related Properties</h4>
                                <div className="item border mb-4">
                                    <div className="overlay d-flex justify-content-center align-items-center">
                                        <Image className="img w-100" src={PropertyListImg1} />
                                        <div className="border text-white px-3 py-2 position-absolute readmore">View More</div>
                                    </div>
                                    <div className="bg-white p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="vm_font_bold">Toronto, On</h4>
                                            <p className="border vm_font_bold px-3 py-0">$ 5,989,800</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-location_small_black me-2"></div>
                                            <p>353 Oakwood Avenue</p>
                                        </div>
                                        <div className="border border-end-0 border-start-0 py-1 mt-2 mb-1 d-flex align-items-center justify-content-between">
                                            <p>Property Type</p>
                                            <span className="bg-dark text-white px-2 py-0">Detached</span>
                                        </div>
                                        <div className="border border-end-0 border-top-0 border-start-0 pb-1 mb-1 d-flex align-items-center justify-content-between">
                                            <p>Size</p>
                                            <p>4,348 SqFt</p>
                                        </div>
                                        <div className="border border-bottom-0 border-end-0 border-top-0 border-start-0 pb-1 d-flex align-items-center justify-content-between">
                                            <p>Rooms & Bath</p>
                                            <p>3 Rooms, 2 Baths</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item border mb-4">
                                    <div className="overlay d-flex justify-content-center align-items-center">
                                        <Image className="img w-100" src={PropertyListImg1} />
                                        <div className="border text-white px-3 py-2 position-absolute readmore">View More</div>
                                    </div>
                                    <div className="bg-white p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="vm_font_bold">Toronto, On</h4>
                                            <p className="border vm_font_bold px-3 py-0">$ 5,989,800</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-location_small_black me-2"></div>
                                            <p>353 Oakwood Avenue</p>
                                        </div>
                                        <div className="border border-end-0 border-start-0 py-1 mt-2 mb-1 d-flex align-items-center justify-content-between">
                                            <p>Property Type</p>
                                            <span className="bg-dark text-white px-2 py-0">Detached</span>
                                        </div>
                                        <div className="border border-end-0 border-top-0 border-start-0 pb-1 mb-1 d-flex align-items-center justify-content-between">
                                            <p>Size</p>
                                            <p>4,348 SqFt</p>
                                        </div>
                                        <div className="border border-bottom-0 border-end-0 border-top-0 border-start-0 pb-1 d-flex align-items-center justify-content-between">
                                            <p>Rooms & Bath</p>
                                            <p>3 Rooms, 2 Baths</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>     
               
            <FooterComponent />
        </>
    );
};
export default PropertyDetails;