import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "@mui/material/Button";

import TextInput from "../inputs/TextInput";
import LoadingButton from "../../../../componets/LoadingButton";

export default function CategoryForm({
  initialValues,
  handleSubmit,
  loading,
  isEdit,
}) {
  const formik = useFormik({
    initialValues: {
      category: initialValues.category,
      image: initialValues.image,
    },
    validationSchema: Yup.object({
      category: Yup.string().required("This field is requried"),
      image: Yup.string().required("This field is requried"),
    }),
    onSubmit: (values) => {
      handleSubmit(values, isEdit);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center mb-5">
            <div>
              {isEdit ? <h5>Edit Category</h5> : <h5>Add New Category</h5>}
            </div>
            <div>
              <LoadingButton
                title={isEdit ? "Update" : "Create"}
                style="primary"
                loading={loading}
                handleClick={() => formik.submitForm()}
              />
            </div>
          </div>

          <div className="col-md-12">
            <TextInput
              id="category"
              label="Category"
              variant="outlined"
              getValue={(value) => formik.setFieldValue("category", value)}
              value={formik.values.category}
              onBlur={() => formik.setFieldTouched("category")}
              error={Boolean(formik.errors.category) && formik.touched.category}
              errorMsg={formik.errors.category}
              type="text"
            />
          </div>

          <div className="col-md-12 my-3">
            {formik.values.image && (
              <img
                src={formik.values.image}
                alt=""
                srcset=""
                height={250}
                width={350}
                className="mb-1"
              />
            )}

            <br />
            <Button variant="contained" component="label">
              {formik.values.image ? "Change" : "Upload"} Category Image
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        formik.setFieldValue("image", fileReader.result);
                      }
                    };
                    fileReader.readAsDataURL(e.target.files[0]);
                  }
                }}
              />
            </Button>
            <br />
            {Boolean(formik.errors.image) && formik.touched.image ? (
              <p
                id="msgError"
                className={
                  "form-text " + Boolean(formik.errors.image)
                    ? "text-danger"
                    : "text-muted"
                }
              >
                {formik.errors.image}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
