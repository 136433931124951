import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import banner from './../assets/img/innerbanner3.webp';
import investimg from './../assets/img/invest_home.webp';
import { Link } from "react-router-dom";

const TITLE = process.env.REACT_APP_SITE_NAME+' - Invest'  

const Invest = () => {
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />      
        <div className="menubackdrop"></div> 
        <section className="py-5">
          <Container className="py-4">
            <h1 className="text-center text-uppercase vm_font_bold">
              Invest
            </h1>
            <div class="d-flex pb-2 justify-content-center">
              <div class="col-md-2">
                <hr />
              </div>
            </div>
            <p class="text-center pb-3 pb-5">
            Are you looking to invest your money into something worthwhile, then you have come to the right place. Sol City Realty can help you find the right investment.
            </p>
        
            <Row>
              <Col md={6}>
                <Image className="w-100" src={investimg} />
              </Col>
              <Col md={6}>
                <h4 className="vm_font_bold pb-3">5 Reasons Why You Should Invest In Real Estate</h4>
                <h5>1.  It’s a tangible asset</h5>
                <h5>2.  Real estate values usually appreciate</h5>
                <h5>3.	You can leverage your equity</h5>
                <h5>4.	Great retirement savings plan</h5>
                <h5>5.	Tax benefits</h5>
              </Col>
            </Row>

            <h3 className="text-center pt-5">
              Let’s find your dream property. Let’s connect.
            </h3>

            <p className="text-center pt-2">
              Sol City Realty is a team of Ontario’s real estate experts. They
              specialize in finding unique homes, cottages, condominiums and
              commercial property located in all regions in the GTA. Our team
              has the experience and dedication to help you. Clients get the
              utmost care and time from our agents, and that is guaranteed.{" "}
            </p>
            <div className="d-flex justify-content-center pt-4">
            <Link to="/contact-us">
                <div class="bg-dark text-white px-3 py-2 border-0">
                  Contact us
                </div>
              </Link>
            </div>
          </Container>
        </section>
      <FooterComponent />
    </>
  );
};

export default Invest;