import HTTP from "../../../common/ajax";

class AuthModel {
	async login(payload) {
		try {
			const res = await HTTP.post("/auth/login", payload);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}

	async refresh(payload) {
		try {
			const res = await HTTP.post("/auth/refresh", payload);
			return Promise.resolve(res.data);
		} catch (error) {
			return Promise.reject(error.response.data);
		}
	}
}

export default new AuthModel();
