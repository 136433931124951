import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import TodoModel from "../../../Models/TodoModel";

function BootstrapAppbar({ active }) {
	// console.log(`active => `, active);
	const history = useHistory();

	const [todos, setTodos] = useState([]);

	const routNavigation = (url) => {
		// console.log("url", url);
		if (url === "logout") {
			localStorage.removeItem("auth");
			history.push("/admin/login");
			return;
		}

		history.push(url);
	};

	const fetchTodos = async () => {
		const res = await TodoModel.fetchTodos();
		setTodos(res.todos);
	};

	useEffect(() => {
		fetchTodos();

		return () => {
			fetchTodos();
		};
	}, []);

	return (
		<nav className="navbar navbar-expand-lg bg-white shadow">
			<a className="navbar-brand" href="#">
				<Link to="/admin/home">
					<img
						src={`${process.env.REACT_APP_API}/storage/settings/logo.png`}
						width=""
						height="35"
						alt=""
						loading="lazy"
					/>
				</Link>
			</a>
			<button
				className="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon"></span>
			</button>

			<div
				className="collapse navbar-collapse"
				id="navbarSupportedContent"
			>
				<ul className="navbar-nav me-auto">
					<li className={active === 'home' ? 'nav-item active' : 'nav-item '}>
						<a className="nav-link" href="">
							<Link to="/admin/home">
								Home <span className="sr-only">(current)</span>
							</Link>
						</a>
					</li>
					{/*<li className="nav-item">
						<a className="nav-link" href="#">
							<Link to="/admin/enquiries">Enquiries</Link>
						</a>
					</li>*/}
					<li className={active === 'own' ? 'nav-item active' : 'nav-item '}>
						<a className="nav-link" href="">
							<Link to="/admin/own">Own Properties</Link>
						</a>
					</li>
					<li className="nav-item dropdown">
						<a
							className="nav-link dropdown-toggle"
							href="#"
							id="navbarDropdown"
							role="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							Enquiries
						</a>
						<div
							className="dropdown-menu"
							aria-labelledby="navbarDropdown"
						>
							<a className="dropdown-item" href="">
								<Link to="/admin/enquiries">Enquiries</Link>
							</a>
							<div className="dropdown-divider"></div>
							<a className="dropdown-item" href="#">
								<Link to="/admin/showings">Showings</Link>
							</a>
						</div>
					</li>
					<li className={active === 'emails' ? 'nav-item active' : 'nav-item '}>
						<a className="nav-link" href="">
							<Link to="/admin/email-list">Emails</Link>
						</a>
					</li>
					<li className={active === 'blogs' ? 'nav-item active' : 'nav-item '}>
						<a className="nav-link" href="">
							<Link to="/admin/blog">Blogs</Link>
						</a>
					</li>
					<li className="nav-item dropdown">
						<a
							className="nav-link dropdown-toggle"
							href="#"
							id="navbarDropdownOthers"
							role="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							More
						</a>
						<div
							className="dropdown-menu"
							aria-labelledby="navbarDropdownOthers"
						>
							<a className="dropdown-item" href="">
								<Link to="/admin/profile">Profile</Link>
							</a>
							<div className="dropdown-divider"></div>
							<a className="dropdown-item" href="">
								<Link to="/admin/settings">Settings</Link>
							</a>
							<div className="dropdown-divider"></div>
							<a className="dropdown-item" href="">
								<Link to="/admin/feedbacks">Feedback</Link>
							</a>
							<div className="dropdown-divider"></div>
							<a className="dropdown-item" href="">
								<Link to="/admin/todos">Todos</Link>
							</a>
							<div className="dropdown-divider"></div>
							<a
								className="dropdown-item"
								href=""
								onClick={() => routNavigation("logout")}
							>
								Logout
							</a>
						</div>
					</li>
				</ul>

				{/* <div className="form-inline">
					<li
						className="nav-item dropdown"
						style={{ listStyle: "none" }}
					>
						<a
							className="nav-link dropdown-toggle caret-off"
							href="#"
							id="navbarDropdown"
							role="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							{todos.length > 0 ? (
								<i class="bi bi-bell-fill text-primary"></i>
							) : (
								<i className="bi bi-bell text-muted"></i>
							)}
						</a>
						<div
							className="dropdown-menu appbar-drop-menu"
							aria-labelledby="navbarDropdown"
						>
							{todos.length > 0 ? (
								<>
									{todos.map((todo, index) => (
										<>
											<a
												className="dropdown-item"
												href="#"
											>
												{todo.title}
											</a>
											<div className="dropdown-divider"></div>
										</>
									))}
								</>
							) : (
								<a className="dropdown-item" href="#">
									Empty
								</a>
							)}
						</div>
					</li>
				</div> */}
			</div>
		</nav>
	);
}

export default BootstrapAppbar;
