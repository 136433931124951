import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
const TITLE = process.env.REACT_APP_SITE_NAME+' - Calculators'  

const Calculator = () => {
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />       
        <div className="menubackdrop"></div>
        <section className="py-5 bg-light">
            <Container className="py-4">
                <h1 className="text-uppercase pb-4 mb-3">Calculators</h1>
                
                <Link to="/landtransfer">
                  <div className="pb-3">
                      <div className="d-md-flex d-lg-block d-block footer-links">
                          <div className="d-flex border py-3 px-3 mt-md-0 mt-lg-3 ">
                              <p className="pl-3">
                              <h5>LAND TRANSFER TAX </h5>
                              <p className="">Determine the amount of land transfer tax you will have to pay. Note that land transfer tax is applied on the sale price only.</p>
                              </p>
                          </div>
                      </div>
                  </div> 
                </Link>
                <Link to="/mortgageloan">
                  <div className="pt-3 pb-3">
                    <div className="d-md-flex d-lg-block d-block footer-links">
                        <div className="d-flex border py-3 px-3 mt-md-0 mt-lg-3 ">
                            <p className="pl-3">
                            <h5>MORTGAGE LOAN CALCULATOR</h5>
                            <p className="">Determine your estimated monthly payment and amortization schedule.</p>
                            </p>
                        </div>
                    </div>
                  </div>
                </Link>
                <Link to="/mortgageaffordability">
                  <div className="pt-3 pb-3">
                    <div className="d-md-flex d-lg-block d-block footer-links">
                        <div className="d-flex border py-3 px-3 mt-md-0 mt-lg-3 ">
                            <p className="pl-3">
                            <h5>MORTGAGE AFFORDABILITY CALCULATOR</h5>
                            <p className="">Can you buy your dream home? Find out just how much you can afford!</p>
                            </p>
                        </div>
                    </div>
                  </div>
                </Link>
                <Link to="/cmhcpremium">
                  <div className="pt-3 pb-5">
                      <div className="d-md-flex d-lg-block d-block footer-links">
                          <div className="d-flex border py-3 px-3 mt-md-0 mt-lg-3 ">
                              <p className="pl-3">
                              <h5>CMHC PREMIUM CALCULATOR</h5>
                              <p className="">A tool to help you estimate the premium payable when you are purchasing a home. Simply enter the purchase price, down payment and the amortization period.</p>
                              </p>
                          </div>
                      </div>
                  </div>
                </Link>
            </Container>
        </section>   
      <FooterComponent />
    </>
  );
};

export default Calculator;