import HTTP from "../common/ajax";
import axios from "axios";
var stringSimilarity = require("string-similarity");

const munAPI = `${process.env.REACT_APP_API}/api/all-municipalities`;
const munList = axios.get(munAPI);

class SearchModel {
  
  async search(query) {
    try {
      const res = await HTTP.post("/search", query);
      //console.log(`res ini => `, res.data);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async comSearch(query) {
    try {
      const res = await HTTP.post("/comsearch", query);
      //console.log(`res ini => `, res.data);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async findSimilarity(keyword) {
    if(keyword){
      var text=[];
      await axios
      .all([munList])
      .then(
        axios.spread((...responses) => {
          const allDataProperies = responses[0];
          // console.log("obj", allDataProperies.data);
        text = Object.entries(allDataProperies.data).map(
            ([key, value]) => value
          );
        })
      )
      .catch((errors) => {
        // react on errors.
        console.log(errors);
      });


      try {
        /*  const addressSuggestions = localStorage.getItem("addressSuggestions");

        if (addressSuggestions.length > 0) {
          for (let i = 0; i < addressSuggestions.length; i++) {
            text.push(addressSuggestions[i]);
          }
        }

        console.log(`text => `, text); */

        var matches = stringSimilarity.findBestMatch(keyword, text);

        return Promise.resolve(matches);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }

  async fetchFeaturedListings(payload) {
    try {
      let res = await HTTP.post("/public/featured-listings", payload);
      return Promise.resolve(res.data.featured);
    } catch (error) {
      return Promise.reject(false);
    }
  }

  async fetchbrokeragelistings (payload) {
    try {
      let res = await HTTP.post("/public/brokerage-listings", payload);
      return Promise.resolve(res.data.brokerageList);
    } catch (error) {
      return Promise.reject(false);
    }
  }

  async fetchAddressSuggestions(payload) {
    try {
      let res = await HTTP.post("/public/address-suggestions", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(false);
    }
  }
}

export default new SearchModel();
