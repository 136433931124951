import React from "react";
import { Link } from "react-router-dom";
import {  Image } from "react-bootstrap";
import NumberFormat from "react-number-format";
import '../../assets/css/propertylist.css'

import NoImageFeature from "../../assets/img/no_image.jpg";

//import CardButton from "./CardButton";

function FeaturedCard({ item }) {
  //console.log("item", item);
  return (
    <Link to={`/estate/details/${item.property_id}/${item.property_type}`}>      
        <div  key={item.property_id} className="item h-100 bg-white">
            <div className="overlay d-flex justify-content-center align-items-center">
              <img className="img w-100"
              src={`${item.images.length >0 ? process.env.REACT_APP_IMG_SOURCE + '/' + item.images[0].url : NoImageFeature}`}/>
                 <Link to={`/estate/details/${item.property_id}/${item.property_type}`} className="border text-white px-3 py-2 position-absolute readmore">View More</Link>
            </div>
            <div className="bg-white p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <NumberFormat value={item.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'}   renderText={(value) => <h4 className="vm_font_bold">{value}</h4>}/>
                    <p className="bg-dark text-white px-2 py-0">
                    {item.Type_own1_out}
                    </p>
                </div>
                <div className="d-flex align-items-center">
                    <div className="bg-location_small_black me-2"></div>
                    <p>{item.Addr}</p>
                </div>
                <div className="d-flex align-items-center">
                    <p>{item.Municipality}, {item.County}</p>
                </div>
                <hr class="my-1 border-bottom border" />
                <div className="d-flex">                    
                    {item.bed_rooms != '' &&        
                        <p>Bed : {item.bed_rooms}</p>
                    }
                    {item.bed_rooms != '' &&   
                        <p className="mx-2">|</p>  
                    }
                         
                    {item.bath_rooms != '' &&   
                        <p>Bath : {item.bath_rooms}</p>
                    }
                    {item.bath_rooms != '' &&   
                        <p className="mx-2">|</p>  
                    } 
                       
                    {item.park_spcs != '' && 
                        <p>Bath : {item.park_spcs}</p>
                    }
                </div>
                <div className="d-flex align-items-center justify-content-start">
                    <p>Size :</p>
                    {item.sqft &&<p className="ps-2">{item.sqft} SqFt</p>}                       
                </div>
            </div>
        </div>      
    </Link>
  );
}

export default FeaturedCard;
