import React from "react";
import { Link } from "react-router-dom";
import NoImageFeature from "../assets/img/no_image.jpg";
import NumberFormat from "react-number-format";
import { Image } from "react-bootstrap";

function RelatedProperty({ property }) {
  return (
    <Link to={`/estate/details/${property.property_id}/${property.property_type}`}>
      <div key={property.property_id} className="border">
        <div className="overlay d-flex justify-content-start align-items-end">
            <Image className="img w-100 property_default_img_sm_size" src={`${property.url && property.url != '' ? process.env.REACT_APP_IMG_SOURCE + '/' + property.url : NoImageFeature}`} />
            <span className="bg-danger text-white px-2 py-0 position-absolute">{property.Type_own1_out}</span>
        </div>
        <div className="bg-white p-3">
            <div>
                <p className="vm_font_bold"><NumberFormat value={property.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat></p>
                <h6 className="lh-base">{property.Addr}</h6>
            </div>
        </div>
    </div>
  </Link>   
  );
}

export default RelatedProperty;
