import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col,Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import innerBanner from "./../assets/img/agent_main_banner5.webp"
import { TelephoneForward, PinMap, Envelope, Facebook, Twitter, Instagram } from 'react-bootstrap-icons';
import { useFormik } from "formik";
import * as Yup from "yup";
import { get, find } from "lodash";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import axios from "../common/ajax";
import { useParams } from "react-router-dom";
import LoadingButton from "../componets/LoadingButton";
import { Link } from "react-router-dom";

import ContactModel from "../Models/ContactModel";
import Spinner from "../componets/Spinner";
import banner from '../assets/img/innerbanner2.webp';

const TITLE = process.env.REACT_APP_SITE_NAME+' - Contact Us'  
const Contactus = (props) => {
    const [loading, setLoading] = useState(0);


async function handleContactForm(payload) {
    try {
      await ContactModel.create({
        ...payload,
      });

        Swal.fire(`Done`, `Submitted successfully!`, "success");
        setLoading(false);
        setTimeout(function () {
            window.location='/';
        }, 2000); 
      
    } catch (error) {
      console.log("Error", error);
      Swal.fire(`Error`, `Something went wrong.`, "error");
      setLoading(false);
    }
  }
  var agent_id=null;
  if(window.sessionStorage.getItem("VM_AGENT_ID")){
    agent_id=window.sessionStorage.getItem("VM_AGENT_ID");
  }

  const formik = useFormik({
    initialValues: {
        firstname: get("", "firstname", ""),
        lastname: get("", "lastname", ""),
        email: get("", "email", ""),
        description: get("", "description", ""),
        phone_number: get("", "phone_number", ""),
        web_url: get("", "web_url",'Contact Us'),
        lead_type: get("", "lead_type",'Contact'),
        agent_id: agent_id,
    },
    validationSchema: Yup.object({
        firstname: Yup.string().min(3, 'First name must be between 3 and 50 characters').max(50, 'First name must be between 3 and 50 characters').required("Please enter your first name"),
        lastname: Yup.string().min(3, 'Last name must be between 3 and 50 characters').max(50, 'Last name must be between 3 and 50 characters').required("Please enter your last name"),
        phone_number: Yup.string().min(10, 'Phone number must be between 10 and 15 characters').max(15, 'Phone number must be between 10 and 15 characters'),
        email: Yup.string().email('Invalid email').required('Please enter your email'),
        description: Yup.string().required("Please enter your message"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleContactForm(values);
    },
  });
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />
        <div className="menubackdrop"></div>        

        <section className="pb-5 pt-4 bg-light">
            <Container className="py-5">
                <h1 className="text-center text-uppercase pb-4 mb-3">Contact Us</h1>
                <Row>
                    <Col md={7}>
                        <div className="bg-white px-5 pt-5 pb-4 vm_boxshadow_1">
                            <Row>
                                <Col className="pb-4" md={6}>
                                <input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    className="form-control rounded-0"
                                    placeholder="First Name *"
                                    value={formik.values.firstname}
                                    onChange={(e) =>
                                        formik.setFieldValue("firstname", e.target.value)
                                    }
                                    onBlur={() => formik.setFieldTouched("firstname")}
                                />
                                {Boolean(formik.errors.firstname) &&
                                formik.touched.firstname ? (
                                    <small
                                    id="firstnameError"
                                    className={
                                        "form-text " + Boolean(formik.errors.firstname)
                                        ? "text-danger"
                                        : "text-muted"
                                    }
                                    >
                                    {formik.errors.firstname}
                                    </small>
                                ) : (
                                    ""
                                )}
                                </Col>
                                <Col className="pb-4" md={6}>
                                <input
                                        type="text"
                                        name="lastname"
                                        id="lastname"
                                        className="form-control rounded-0"
                                        placeholder="Last Name *"
                                        value={formik.values.lastname}
                                        onChange={(e) =>
                                        formik.setFieldValue("lastname", e.target.value)
                                        }
                                        onBlur={() => formik.setFieldTouched("lastname")}
                                    />
                                    {Boolean(formik.errors.lastname) &&
                                    formik.touched.lastname ? (
                                        <small
                                        id="lastnameError"
                                        className={
                                            "form-text " + Boolean(formik.errors.lastname)
                                            ? "text-danger"
                                            : "text-muted"
                                        }
                                        >
                                        {formik.errors.lastname}
                                        </small>
                                    ) : (
                                        ""
                                    )}
                                </Col>
                                
                                <Col className="pb-4" md={6}>
                                <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            className="form-control rounded-0"
                                            placeholder="Email *"
                                            value={formik.values.email}
                                            onChange={(e) =>
                                            formik.setFieldValue("email", e.target.value)
                                            }
                                            onBlur={() => formik.setFieldTouched("email")}
                                        />
                                        {Boolean(formik.errors.email) &&
                                        formik.touched.email ? (
                                            <small
                                            id="email_addressError"
                                            className={
                                                "form-text " + Boolean(formik.errors.email)
                                                ? "text-danger"
                                                : "text-muted"
                                            }
                                            >
                                            {formik.errors.email}
                                            </small>
                                        ) : (
                                            ""
                                        )}
                                </Col>
                                <Col className="pb-4" md={6}>
                                <input
                                            type="text"
                                            name="phone_number"
                                            id="phone_number"
                                            className="form-control rounded-0"
                                            placeholder="Phone Number"
                                            value={formik.values.phone_number}
                                            onChange={(e) =>
                                            formik.setFieldValue("phone_number", e.target.value)
                                            }
                                            onBlur={() => formik.setFieldTouched("phone_number")}
                                        />
                                        {Boolean(formik.errors.phone_number) &&
                                        formik.touched.phone_number ? (
                                            <small
                                            id="phone_numberError"
                                            className={
                                                "form-text " + Boolean(formik.errors.phone_number)
                                                ? "text-danger"
                                                : "text-muted"
                                            }
                                            >
                                            {formik.errors.phone_number}
                                            </small>
                                        ) : (
                                            ""
                                        )}
                                </Col>
                                <Col className="pb-4" md={12}>
                                <textarea
                                        name="message"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        className="form-control rounded-0"
                                        placeholder="Message *"
                                        value={formik.values.description}
                                        onChange={(e) =>
                                        formik.setFieldValue("description", e.target.value)
                                        }
                                        onBlur={() => formik.setFieldTouched("description")}
                                    ></textarea>
                                    {Boolean(formik.errors.description) && formik.touched.description ? (
                                        <small
                                        id="enquiryError"
                                        className={
                                            "form-text " + Boolean(formik.errors.description)
                                            ? "text-danger"
                                            : "text-muted"
                                        }
                                        >
                                        {formik.errors.description}
                                        </small>
                                    ) : (
                                        ""
                                    )}     
                                </Col>
                                <Col md={12}>
                                    <LoadingButton
                                        className=""
                                        title="Submit Message"
                                        style="btn_dark px-3 py-2"
                                        loading={loading}
                                        handleClick={() => formik.submitForm()}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="bg-white h-100 w-100 vm_boxshadow_1 p-5">
                            <h3 className="pb-3 vm_font_bold">{window.sessionStorage.getItem("VM_APP_AGENT_NAME")}</h3>
                            <div className="d-flex align-items-center">
                                <PinMap color="black"/>
                                <p className="ps-3">{process.env.REACT_APP_ADDRESS1_1}<br/>{process.env.REACT_APP_ADDRESS1_2}</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <TelephoneForward color="black"/>
                                <p className="ps-3">{window.sessionStorage.getItem("VM_APP_PHONE_NUMBER")} </p>
                            </div>
                            <div className="d-flex align-items-center">
                                <Envelope color="black"/>
                                <p className="ps-3">{window.sessionStorage.getItem("VM_APP_EMAIL")}</p>
                            </div>
                            <h4 className="d-flex align-items-center pt-3">
                                <a href={window.sessionStorage.getItem("VM_APP_FB_LINK")} target="_blank" rel="noopener noreferrer"><Facebook color="black"/></a>
                                {/* <a href={window.sessionStorage.getItem("VM_APP_TWITTER_LINK} target="_blank" rel="noopener noreferrer"><Twitter className="mx-2"/></a> */}
                                <a href={window.sessionStorage.getItem("VM_APP_INSTA_LINK")} target="_blank" rel="noopener noreferrer"><Instagram className="mx-2" color="black"/></a>
                            </h4>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>  
        <iframe src={process.env.REACT_APP_IFRAME_SRC} width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> 
      <FooterComponent />
    </>
  );
};

export default Contactus;