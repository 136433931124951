import React from "react";
import { Helmet } from "react-helmet";
import '../assets/css/homevalue.css'
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
const TITLE = 'Home Value2'

const HomeValue2 = () => {
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <HeaderComponent />
            <div className="menubackdrop"></div>

            <section className="homevalue">
                <div className="homevalue_2">
                    <div className="overlay py-5 w-100 h-100">
                        <Container className="pb-4">
                            <h2 className="text-white text-uppercase pb-2">Property Found</h2>
                            <Row>
                                <Col md={6}>
                                    <div className="bg-dark w-100 h-100 googlemap"></div>
                                </Col>
                                <Col md={6}>
                                    <div className="bg-white p-5">
                                        <h3 className="pb-4">Tell us what you are looling for</h3>
                                        <form>
                                            <select name="propertytype" className="form-contorl rounded-0 form-select mb-4">
                                                <option vlaue="">Property Type</option>
                                            </select>
                                            <select name="bed" className="form-contorl rounded-0 form-select mb-4">
                                                <option vlaue="">No of Bedrooms</option>
                                            </select>
                                            <select name="garage" className="form-contorl rounded-0 form-select mb-4">
                                                <option vlaue="">Garage Reference</option>
                                            </select>
                                            <select name="basement" className="form-contorl rounded-0 form-select mb-4">
                                                <option vlaue="">Basement</option>
                                            </select>
                                            <button className="vm_bg_secondary text-white border-0 rounded-0 px-3 py-2">Continue</button>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>     
            <FooterComponent />
        </>
    );
};
export default HomeValue2;