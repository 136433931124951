import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";

import ShowingsModel from "../Models/ShowingsModel";

const paraStyles = {
  color: "#949497",
};

const subHeadingStyle = {
  color: "#303030",
  fontWeight: 600,
};

const ShowingsList = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const fetchShowing = async () => {
    const res = await ShowingsModel.fetchShowing(id);

    if (res.data.length > 0) {
      setList(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchShowing();

    return () => {
      fetchShowing();
    };
  }, []);

  return (
    <>
      <HeaderComponent />

      <div className="container-fluid my-5">
        <div class="row justify-content-center">
          <div class="col-8">
            <div className="shadow p-5">
              <div className="border-bottom">
                <h5 className="pb-2">Your visiting list</h5>
              </div>

              <div>
                {list.length > 0 ? (
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Address</th>
                        <th scope="col">Date and Time</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((ls, index) => (
                        <tr className="showings-table" key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{ls.address}</td>
                          <td>{ls.availableTime}</td>
                          <td>
                            <a
                              href="https://www.google.com/maps/dir/?api=1&destination=15%20Provost%20Dr%2C%20Toronto%2C%20ON%20M2K%202X9%2C%20Canada&dir_action=navigate"
                              target="_blank"
                              className="btn btn-primary"
                            >
                              <i class="bi bi-search me-2"></i>Find Route
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="border text-center my-5">
                    <h5 className="py-5">
                      <p style={{ fontSize: 17, fontWeight: "600" }}>
                        {loading ? "Please wait. . ." : "No data found "}
                      </p>
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterComponent />
    </>
  );
};

export default ShowingsList;
