import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

const CategoryCard = ({ category }) => {
  return (
    <Link to={`/blogs/${category.category}`}>
      <div className="bg-white vm_boxshadow_1 border">
          <div>
              <Image className="w-100" src={category.image}/>
          </div>
          <div className="bg-dark p-3">
              <h3 className="text-center text-white">{category.category}</h3>
          </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
