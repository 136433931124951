import React from "react"
import { Link } from 'react-router-dom';
import { Nav, Navbar } from "react-bootstrap";
import { CaretDownFill } from 'react-bootstrap-icons';

// const Header = () => {
class Mainmenu extends React.Component {
    render() {    
        return (          
            <ul className="menu">
                <Link to="/" className="item" onclick="hide_nav()">Home</Link>
                <Link to="/About" className="item" onclick="hide_nav()">About us</Link>
                <span className="item" onclick="hide_nav()">Listings <CaretDownFill className="caret mt-1 ms-1" color="white" size={11} />
                    <ul className="submenu">
                        <div className="spacer"></div>
                        <Link to="/featuredlistings" className="item" onclick="hide_nav()">Featured Listings</Link>
                        <Link to="/allcommerical" className="item" onclick="hide_nav()">Commercial Lisings</Link>
                        <div className="submenu2">
                            <Link to="/commerical/Industrial" className="item" onclick="hide_nav()">Industrial | Warehouse</Link>
                            <Link to="/commerical/Land" className="item" onclick="hide_nav()">Land</Link>
                            <Link to="/commerical/Office" className="item" onclick="hide_nav()">Office</Link>
                            <Link to="/commerical/Investment" className="item" onclick="hide_nav()">Investment</Link>
                        </div>
                        <Link to="/allresidential" className="item" onclick="hide_nav()">Residential Listing</Link>
                        <div className="submenu2">
                            <Link to="/residential/Detached" className="item" onclick="hide_nav()">Detached Homes</Link>
                            <Link to="/residential/Semi-Detached" className="item" onclick="hide_nav()">Semi-Detached Homes</Link>
                            <Link to="/residential/condo" className="item" onclick="hide_nav()">Condos</Link>
                            <Link to="/residential/Triplex" className="item" onclick="hide_nav()">Triplex</Link>
                            <Link to="/residential/Multiplex" className="item" onclick="hide_nav()">Multiplex</Link>
                        </div>
                        {/* <Link to="/" className="item" onclick="hide_nav()">Pre-Construction</Link>
                        <Link to="/" className="item" onclick="hide_nav()">Exclusive Listings</Link> */}
                    </ul>
                </span>
                {/* <Link to="/buy" className="item" onclick="hide_nav()">Buy</Link>
                <Link to="/sell" className="item" onclick="hide_nav()">Sell</Link>
                <Link to="/development" className="item" onclick="hide_nav()">Development</Link> */}
                <Link to="/ourteam" className="item" onclick="hide_nav()">Meet the Team</Link>
                <Link to="/" className="item" onclick="hide_nav()">Clients <CaretDownFill className="caret mt-1 ms-1" color="white" size={11} />
                    <ul className="submenu">
                        <div className="spacer"></div>
                        <Link to="/pricedropalert" className="item" onclick="hide_nav()">Price Drop Alert</Link>
                        <Link to="/homeworth" className="item" onclick="hide_nav()">Home Value Estimator</Link>
                        <Link to="/homevalue" className="item" onclick="hide_nav()">Find My Dream Home</Link>
                        {/* <Link to="/buy" className="item" onclick="hide_nav()">Guide to Buying Your Home</Link>
                        <Link to="/sell" className="item" onclick="hide_nav()">Guide to Selling Your Home</Link> */}
                        <Link to="/calculators" className="item" onclick="hide_nav()">Calculators</Link>
                    </ul>
                </Link>
                <Link to="/blogs" className="item" onclick="hide_nav()">Blogs</Link>
                <Link to="/contact-us" className="item" onclick="hide_nav()">Contact us</Link>
            </ul>
        )
    }
}

export default Mainmenu;