import HTTP from "../common/ajax";

class WorthModel {
  async create(payload) {
    try {
      const res = await HTTP.post("public/worthsubmit/new", payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }
}

export default new WorthModel();
