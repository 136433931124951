import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Swal from "sweetalert2";

import RootElement from "../components/RootElement";
import EnquiryForm from "../components/forms/EnquiryForm";

import EnquiryModel from "../../../Models/EnquiryModel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #ccc",
  boxShadow: 24,
  p: 4,
};

export default function StickyHeadTable(props) {
  const [loading, setLoading] = React.useState(false);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState(10);
  const [enquiries, setEnquiries] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = async () => {
    await EnquiryModel.fetchEnquiries();
    setOpen(false);
  };

  /* const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; */

  /* const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }; */

  const markAsCompleted = async (id) => {
    setLoading(true);
    try {
      await EnquiryModel.reply({ enquiry_id: id, type: "complete" });
      fetchEnquiries();
      setLoading(false);
      Swal.fire("Done", "Marked as completed", "success");
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      Swal.fire("error", "Something went wrong.", "error");
    }
  };

  const fetchEnquiries = async () => {
    try {
      const res = await EnquiryModel.fetchEnquiries();
      setEnquiries(res.results);
      console.log("res", res);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    fetchEnquiries();
  }, []);

  return (
    <RootElement>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <div className="my-5">
        {enquiries.length <= 0 ? (
          <div className="text-center pt-5">
            <p>Currently no enquires</p>
          </div>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Full Name</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Message</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>

            <tbody>
              {enquiries &&
                enquiries.map((row, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{row.full_name}</td>
                    <td>{row.phone_number}</td>
                    <td>{row.enquiry}</td>
                    <td>
                      <div>
                        <button
                          className="btn btn-sm border text-success me-2"
                          onClick={() => {
                            setData(row);
                            handleOpen();
                          }}
                          disabled={row.status === "ACCEPTED" ? true : false}
                          data-toggle="tooltip" data-placement="top" title="Accept"
                        >
                          <CheckCircleOutlineIcon />
                        </button>
                        <button
                          className="btn btn-sm border text-danger"
                          onClick={() => markAsCompleted(row.id)}
                          disabled={
                            row.status === "ACCEPTED" &&
                              row.status !== "COMPLETED"
                              ? false
                              : true
                          }
                          data-toggle="tooltip" data-placement="top" title="Mark as completed"
                        >
                          <DoneAllIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div
              className="my-3"
              style={{ border: "1px solid #ccc", borderRadius: 7, padding: 15 }}
            >
              <div className="d-md-flex">
                <div className="d-flex my-1 me-3">
                  <strong className="font-weight-bold">Customer Name: </strong>
                  <strong className="ps-2">{data.full_name}</strong>
                </div>
                <div className="d-flex my-1">
                  <strong className="font-weight-bold">Email: </strong>
                  <strong className="ps-2">{data.email_address}</strong>
                </div>
              </div>
              <div className="d-flex my-1">
                <strong className="font-weight-bold">Phone: </strong>
                <strong className="ps-2">{data.phone_number}</strong>
              </div>
              <div className="d-flex my-1">
                <strong className="font-weight-bold">Message: </strong>
                <strong className="ps-2">{data.enquiry}</strong>
              </div>
            </div>

            <EnquiryForm
              initialValues={data}
              closeModal={handleClose}
              fetchEnquiries={fetchEnquiries}
            />
          </Box>
        </Fade>
      </Modal>
    </RootElement>
  );
}
