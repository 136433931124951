import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get, find } from "lodash";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import axios from "./../../common/ajax";
import { useParams,Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image, Tab, Tabs } from "react-bootstrap";
import NumberFormat from "react-number-format";

import { setShowings } from "../../store/actions/App/AppActions";
import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";
import LoadingButton from "../../componets/LoadingButton";

import OwlCarousel from "react-owl-carousel";
import pd_gal_1 from "./../../assets/img/pd_gal_1.png";
import agent_footer_photo from "./../../assets/img/agent_footer_photo.webp";
import EnquiryModel from "../../Models/EnquiryModel";
import Spinner from "../../componets/Spinner";
import ImageViewer from "../../componets/ImageViewer";
import moment from "moment";
import RelatedProperty from "../../componets/RelatedProperty";
import Skeleton from "@mui/material/Skeleton";

import Modal from "../../componets/Modal";
import GoogleMap from "../admin/components/GoogleMap";

import { TelephoneForward, Heart, ShareFill, PinMapFill } from 'react-bootstrap-icons';
import ImageGallery from 'react-image-gallery';
import agentLogo from './../../assets/img/agent_logo.webp';
import PropertyListImg1 from './../../assets/img/propimg1.webp'
import PropertyListImg2 from './../../assets/img/propimg2.webp'

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const EstateDetails = (props) => {
  const { propertyId, propertyType } = useParams();       

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(0);
  const [count, setCount] = useState(false);
  // const [sent, setSent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [propertyDetails, setPropertyDetails] = useState({
    propert_details: [],
  });
  const [imagesnew, setImagesnew] = useState();
  const [randomProperties, setRandomProperties] = useState([]);
  const [totalRooms, setTotalRooms] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getpropertyDetailsWithImage();
  }, [propertyId, propertyType]);

  const getpropertyDetailsWithImage = async () => {
    setIsLoading(true);
    setLoading(true);

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    await axios
      .get(`properties/details/${propertyId}/${propertyType}`, {})
      .then((res) => {
        const data = res.data.res;
        const imgsrc = res.data.imgsrc;

        const _PD = data.propert_details[0];
        if (!_PD || _PD === null || _PD === undefined) {
          //console.log(`_PD => `, _PD);
          return;
        }

        let rooms = [];
        let noOfRooms = [
          "Rm1",
          "Rm2",
          "Rm3",
          "Rm4",
          "Rm5",
          "Rm6",
          "Rm7",
          "Rm8",
          "Rm9",
          "Rm10",
          "Rm11",
          "Rm12",
        ];

        for (let r = 0; r < noOfRooms.length; r++) {
          const noOfRoom = noOfRooms[r];
          let data = {};
          let s=r+1;
          let col_name_1=noOfRoom+'_dc1_out';
          let col_name_2=noOfRoom+'_dc2_out';
          let col_name_3=noOfRoom+'_dc3_out';
          let col_name_4=noOfRoom+'_out';
          let col_name_5=noOfRoom+'_len';
          let col_name_6=noOfRoom+'_wth';

          if(Object.hasOwn(_PD,col_name_4)){
              data = {
                ...data,
                dc1: _PD[col_name_1],
                dc2: _PD[col_name_2],
                dc3: _PD[col_name_3],
                out: _PD[col_name_4],
                lenvar: _PD[col_name_5],
                width: _PD[col_name_6]
              };
          }
          rooms.push(data);
        }
        console.log(`rooms => `, rooms);

        if (res.data.randomProperties) {
          setRandomProperties(res.data.randomProperties);
        }

        setTotalRooms(rooms);
        setIsLoading(false);
        setPropertyDetails(data);
        setImagesnew(imgsrc);
        setCount(count + 1);        
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setLoading(false);
      });
  };

  async function handleEnquiryForm(payload) {
    try {
      await EnquiryModel.create2({
        property_id: propertyId,
        property_type: propertyType,
        ...payload,
      });

      Swal.fire(`Done`, `Submitted successfully`, "success");
      setLoading(false);

      formik.setFieldValue("full_name", "");
      formik.setFieldValue("email_address", "");
      formik.setFieldValue("phone_number", "");
      formik.setFieldValue("enquiry", "");
    } catch (error) {
      console.log("Error", error);
      Swal.fire(`Error`, `Something went wrong.`, "error");
      setLoading(false);
    }
  }
  
  const formik = useFormik({
    initialValues: {
      full_name: get("", "name", ""),
      email_address: get("", "email", ""),
      enquiry: get("", "msg", ""),
      phone_number: get("", "phone_number", ""),
    },
    validationSchema: Yup.object({
      full_name: Yup.string().min(3, 'Name must be between 3 and 50 characters').max(50, 'Name must be between 3 and 50 characters').required("This field is requried"),
      phone_number: Yup.string().min(10, 'Phone number must be between 10 and 15 characters').max(15, 'Phone number must be between 10 and 15 characters'),
      email_address: Yup.string().email('Invalid email').required('This field is required'),
      enquiry: Yup.string().required("This field is requried"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleEnquiryForm(values);
    },
  });

  function prepareUrl(url) {
    return `${process.env.REACT_APP_IMG_SOURCE}/${url}`;
  }

  function addToShowings(payload) {
    let storage = JSON.parse(localStorage.getItem("showings"));

    if (storage) {
      const found = storage.find(
        (pr) => pr.property_id === payload.property_id
      );

      if (found !== undefined || found) {
        Swal.fire(
          "Already Exist",
          "Property already in showings list.",
          "warning"
        );
        return;
      }
    }

    props.setShowings(payload);

    Swal.fire("Success", "Property added to showings list.", "success");

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  
  /* useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []); */
  const [showMap, setShowMap] = useState(false);
  const TITLE = process.env.REACT_APP_SITE_NAME+' - '+propertyDetails.Addr

  return (
    <>
      <Helmet>
          <title>{ TITLE }</title>
      </Helmet>
      <HeaderComponent />
      <div className="menubackdrop"></div>
      <section className="py-5 property_details">
        <Container>
            <Row>
                <Col md={8}>
                  <div className="wrapper">                        
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width={250}
                          height={250}
                        />
                      </div>
                    ) : (
                      <>
                      {imagesnew && imagesnew.length>0 ? (
                        <ImageGallery 
                          items={imagesnew}
                          infinite={true}
                          lazyLoad={true}
                          autoPlay={true}
                          slideDuration={1000}
                        />  
                        ) : (
                          ''
                        )}
                      </>
                    )}                                      
                  </div>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <div>
                       <div className="pt-2 border-top d-md-flex justify-content-between border-bottom">
                        <div>
                          {/* Price Section */}
                          <div class="d-md-flex align-items-end pb-4">
                            {/* List Price */}
                            <div className="pb-3 pb-md-0">
                              <p className="text-muted">List Price</p>
                              <NumberFormat value={propertyDetails.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'}   renderText={(value) => <h2 className="vm_font_bold">{value}</h2>}/>
                              {get(propertyDetails.propert_details[0],'Yr_built') &&
                                <div className="d-flex pt-1">
                                  <h5 className="text-muted">Age of Home</h5>
                                  <h5 className="prop_detail_highlighted">: {get(propertyDetails.propert_details[0], "Yr_built", "No Data")}</h5>
                                </div>
                              }
                            </div>                           
                          </div>
                        </div>

                        {/* Address Line, Share and Like */}
                        <div class="pb-4 d-md-flex flex-column justify-content-end align-items-end">
                          <div className="d-md-flex justify-content-end">
                            <div className="d-flex justify-content-right">
                              <div className="d-flex align-items-center border px-2 py-1">
                                <ShareFill color="gray" size={16} />
                                <p className="ps-2 text-muted">Share</p>
                              </div>
                              <div className="d-flex align-items-center ms-md-3 border px-2 py-1 ms-2 ms-md-0">
                                <Heart color="gray" size={16} />
                                <p className="ps-2 text-muted">Save</p>
                              </div>
                            </div>
                          </div>
                          <p className="text-muted pt-2">
                          {propertyDetails.Addr && propertyDetails.Addr}, {propertyDetails.Municipality && propertyDetails.Municipality}, {propertyDetails.County && propertyDetails.County}
                          </p>
                        </div>

                      </div>
                      <div className="pt-4 d-flex flex-wrap align-items-center justify-content-md-between">
                        {propertyDetails.Type_own1_out &&
                          <div className="d-flex align-items-center border p-3 mb-3 mb-md-0 me-3 shadow-sm">
                            <div className="bg-icon_house opacity-50"></div>
                            <h6 className="ps-2 vm_font_bold">{propertyDetails.Type_own1_out}</h6>
                          </div>
                        }
                        {propertyDetails.bed_rooms && 
                        <div className="d-flex align-items-center border p-3 mb-3 mb-md-0 me-3 shadow-sm">
                          <div className="bg-icon_bed opacity-50"></div>
                          <h6 className="ps-2 vm_font_bold">{propertyDetails.bed_rooms} Beds</h6>
                        </div>
                        }
                        {propertyDetails.bath_rooms && 
                        <div className="d-flex align-items-center border p-3 mb-3 mb-md-0 me-3 shadow-sm">
                          <div className="bg-icon_bath opacity-50"></div>
                          <h6 className="ps-2 vm_font_bold">{propertyDetails.bath_rooms} Baths</h6>
                        </div>
                        }
                        {propertyDetails.park_spcs && 
                        <div className="d-flex align-items-center border p-3 mb-3 mb-md-0 me-3 shadow-sm">
                          <div className="bg-icon_parking opacity-50"></div>
                          <h6 className="ps-2 vm_font_bold">{propertyDetails.park_spcs} Parking Spots</h6>
                        </div>
                        }
                        {propertyDetails.sqft && 
                        <div className="d-flex align-items-center border p-3 mb-3 mb-md-0 shadow-sm">
                          <div className="bg-icon_square opacity-50"></div>
                          <h6 className="ps-2 vm_font_bold">{propertyDetails.sqft} Sq Ft</h6>
                        </div>
                        }
                      </div>                          
                      <div className="pt-2 mt-2">
                        <div className="row">                           
                             {/* Description */}
                            <h4 className="pb-1 mt-2">Description</h4>
                            <div className="m-2 p-4 shadow-sm border bg-light">
                              <div className="d-flex justify-content-start align-items-start flex-column flex-column-reverse">
                               
                                <p
                                  className="property_description"
                                  id="property_description"
                                >
                                {propertyDetails.Ad_text && propertyDetails.Ad_text}
                                </p>
                              </div>
                            </div>
                            <div className="pt-4">
                              <h4 class="pb-2">Property Details</h4>
                              <div>
                              <div className="d-flex justify-content-md-between justify-content-start pb-1 col-md-12">
                                  <p class="property_description">Property Type
                                   : {get(propertyDetails.propert_details[0],"Style","No Data")}</p>
                                </div>
                                <div className="d-flex justify-content-md-between justify-content-start pb-4 col-md-12">
                                <p class="property_description">Area : {get(propertyDetails.propert_details[0],"Area","N/A")}</p>
                                </div>
                              </div>

                              <div className="property_features_col_1 pb-4">
                                {/* Left column */}
                                <div className="border p-4 shadow-sm">
                                  <div className="d-flex ">
                                      <div className="col">
                                          <p>Added on Market</p>
                                      </div>
                                      <div className="col">
                                          <p>: {moment(propertyDetails.Timestamp_sql).format("MMM, D, YYYY")} ({moment(propertyDetails.Timestamp_sql).fromNow()})</p>
                                      </div>
                                  </div>   
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>List Price</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: <NumberFormat value={propertyDetails.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'}/> | For {propertyDetails.S_r}</p>
                                      </div>
                                  </div>
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>MLS Number</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {propertyDetails.Ml_num}</p>
                                      </div>
                                  </div>
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Municipality District</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Municipality_district", "N/A")}</p>
                                      </div>
                                  </div>
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Community</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0],"Community","N/A")}
                                          </p>
                                      </div>
                                  </div>  
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Cross Street</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Cross_st", "N/A")}</p>
                                      </div>
                                  </div>
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Zoning</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0],"zoning","No Data")}</p>
                                      </div>
                                  </div>
                                  <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Tax Amount</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Taxes", "N/A")}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Tax Year</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Yr", "N/A")}</p>
                                        </div>
                                    </div>
                                </div>
                              </div>

                              <div className="property_features_col_1 pb-4 pt-3">
                                {/* Tax Details */}
                                <div className="wrapper_full border p-4 shadow-sm alert alert-success rounded-0">
                                  <div className="col">
                                    <Row>
                                      <Col md={6} className="content_wrapper">
                                        <p>Lot Size </p>
                                        <p>: {get(propertyDetails.propert_details[0], "Front_ft", "No Data")} X {get(propertyDetails.propert_details[0], "Depth", "No Data")} Feet</p>
                                      </Col>
                                      <Col md={6} className="content_wrapper">
                                        <p>Age of Home </p>
                                        <p>
                                          : {get(propertyDetails.propert_details[0], "Yr_built", "No Data")}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>

                              <div className="property_features_col_2 pb-2">
                                {/* Left column */}
                                <div className="wrapper border p-4 me-md-3 shadow-sm">
                                <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Parking Space</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Park_spcs", "No Data")}</p>
                                      </div>
                                  </div>
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Kitchens</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Kitchens", "No Data")}</p>
                                      </div>
                                  </div>   
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Rooms</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Rms", "No Data")}</p>
                                      </div>
                                  </div>  
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Rooms Plus</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Rooms_plus", "No Data")}</p>
                                      </div>
                                  </div>                                     
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Den/Family Room</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Den_fr", "No Data")}</p>
                                      </div>
                                  </div>                                     
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Walk Score</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Walk_score", "N/A")}
                                          </p>
                                      </div>
                                  </div>  
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Driveway</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Drive", "N/A")}
                                          </p>
                                      </div>
                                  </div>  
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Garage</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Gar_type", "N/A")}
                                          </p>
                                      </div>
                                  </div>  
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Basement</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Bsmt1_out", "N/A")}
                                          </p>
                                      </div>
                                  </div>  
                                  <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Parcel Number</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Parcel_id", "N/A")}</p>
                                        </div>
                                    </div>                               
                                </div>

                                {/* Right column */}
                                <div className="wrapper border p-4 ms-md-3 shadow-sm">
                                  <div className="d-flex ">
                                      <div className="col-md-6 col">
                                          <p>Heating</p>
                                      </div>
                                      <div className="col-md-6 col">
                                          <p>: {get(propertyDetails.propert_details[0], "Heat_inc", "N/A")}
                                          </p>
                                      </div>
                                  </div>   
                                  <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Heating Fuel</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Heating", "N/A")}
                                            </p>
                                        </div>
                                    </div>  
                                    <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Fireplace</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Fpl_num", "N/A")}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Air Conditioning</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "A_c", "N/A")}</p>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Frontage</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Front_ft", "N/A")}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Pool</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Pool", "N/A")}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Sewer</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Sewer", "N/A")}</p>
                                        </div>
                                    </div>
                                   

                                    
                                  
                                    
                                    <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Water supply</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Water", "N/A")}
                                            </p>
                                        </div>
                                    </div>  
                                    
                                    <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Exterior</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Constr1_out", "N/A")}
                                            </p>
                                        </div>
                                    </div>  
                                    
                                    <div className="d-flex ">
                                        <div className="col-md-6 col">
                                            <p>Exterior Features</p>
                                        </div>
                                        <div className="col-md-6 col">
                                            <p>: {get(propertyDetails.propert_details[0], "Constr2_out", "N/A")}
                                            </p>
                                        </div>
                                    </div>  
                                </div>
                              </div>
                            </div>               
                        </div>
                      </div>
                      <div className="p-4 mt-4 me-md-3 wrapper border shadow-sm">
                          <h4 className="pb-3">Interior Rooms</h4>
                          {totalRooms &&
                            totalRooms.length > 0 &&
                            totalRooms.map((room, index) => ( 
                              <div key={index}>                             
                                {room.out && (
                                  <div  className="row mr-3 border-bottom mb-2">
                                  <Col md={3}><p>{room.out}</p></Col>
                                  <Col md={9}>                                    
                                    <p>{room.width} x {room.lenvar}, {room.dc1},{room.dc2},{room.dc3}</p>
                                  </Col>
                                  </div>
                                )}
                              </div>
                            ))}                          
                      </div>
                    </div>
                   )}   
                </Col>                
                <Col className="ps-md-4" md={4}>
                  <div className="vm_boxshadow_1 border">
                      <p className="position-absolute bg-dark text-white px-2 py-1">Presented by</p>
                      <div className="d-flex justify-content-center align-items-center pt-5  mt-4">
                          <Image src={agentLogo} />
                      </div>
                      <div className="d-flex justify-content-center align-items-center pb-5">
                          <TelephoneForward color="black" size={20} />
                          <h4 className="ps-2">{process.env.REACT_APP_PHONE_NUMBER}</h4>
                      </div>
                      <div className="d-flex px-4 pb-4">
                          <h4 className="bg-dark text-white text-center p-3 col">
                              Book a Showing
                          </h4>                          
                          <Link to="/contact-us" onclick="hide_nav()"><h4 className="bg-danger text-white text-center p-3 col">
                            Contact us
                          </h4></Link>                          
                      </div>
                  </div>
                  <div className="vm_boxshadow_1 border mt-4 p-4">
                      <h4 className="text-center pb-4 vm_font_bold">Request More Information</h4>
                      <form onSubmit={formik.handleSubmit}>
                            <input 
                              className="form-control rounded-0 mb-3"
                              type="text"  
                              name="fullname"
                              id="fullname"
                              placeholder="Fullname *"
                              value={formik.values.full_name}
                              onChange={(e) =>
                                formik.setFieldValue("full_name", e.target.value)
                              }
                              onBlur={() => formik.setFieldTouched("full_name")}
                            />
                            {Boolean(formik.errors.full_name) &&
                            formik.touched.full_name ? (
                            <small
                              id="full_nameError"
                              className={
                                "form-text " + Boolean(formik.errors.full_name)
                                  ? "text-danger"
                                  : "text-muted"
                              }
                            >
                              {formik.errors.full_name}
                            </small>
                            ) : (
                            ""
                            )}
                            <input className="form-control rounded-0 mb-3" 
                            name="number"
                            id="number"
                            placeholder="Phone Number"
                            value={formik.values.phone_number}
                            onChange={(e) =>
                              formik.setFieldValue("phone_number", e.target.value)
                            }
                            onBlur={() => formik.setFieldTouched("phone_number")}
                            />
                            {Boolean(formik.errors.phone_number) &&
                            formik.touched.phone_number ? (
                            <small
                              id="phone_numberError"
                              className={
                                "form-text " + Boolean(formik.errors.phone_number)
                                  ? "text-danger"
                                  : "text-muted"
                              }
                            >
                              {formik.errors.phone_number}
                            </small>
                            ) : (
                            ""
                            )}
                            <input className="form-control rounded-0 mb-3 email" type="email"
                            name="email_address"
                            id="email_address"
                            placeholder="Email Address *"
                            value={formik.values.email_address}
                            onChange={(e) =>
                              formik.setFieldValue("email_address", e.target.value)
                            }
                            onBlur={() => formik.setFieldTouched("email_address")}
                            />
                            {Boolean(formik.errors.email_address) &&
                            formik.touched.email_address ? (
                            <small
                              id="email_addressError"
                              className={
                                "form-text " + Boolean(formik.errors.email_address)
                                  ? "text-danger"
                                  : "text-muted"
                              }
                            >
                              {formik.errors.email_address}
                            </small>
                            ) : (
                            ""
                            )}
                            <textarea className="rounded-0 form-control" row="50"
                            name="message"
                            id="message"
                            cols="30"
                            rows="5"
                            placeholder="I would like more information regarding this property *"
                            value={formik.values.enquiry}
                            onChange={(e) =>
                              formik.setFieldValue("enquiry", e.target.value)
                            }
                            onBlur={() => formik.setFieldTouched("enquiry")}
                            ></textarea>
                            {Boolean(formik.errors.enquiry) && formik.touched.enquiry ? (
                            <small
                              id="enquiryError"
                              className={
                                "form-text " + Boolean(formik.errors.enquiry)
                                  ? "text-danger"
                                  : "text-muted"
                              }
                            >
                              {formik.errors.enquiry}
                            </small>
                            ) : (
                            ""
                            )}
                            
                            <div className="d-flex justify-content-center">
                              <LoadingButton
                              title="Send Now"
                              style="vm_bg_secondary text-white rounded-0 border-0 py-1 px-3 mt-3"
                              loading={loading}
                              handleClick={() => formik.submitForm()}
                              />
                            </div>
                        </form> 
                  </div>
                  {randomProperties && (randomProperties.length > 0) &&
                    <div className="pt-4">
                    <h4 className="text-center pb-4 vm_font_bold">Related Properties</h4>
                    <div className="row">

                      {randomProperties &&
                      randomProperties.map((rPropertery, index) => (
                        <div key={index} className="item col-md-6 mb-4">
                          <RelatedProperty property={rPropertery} />
                        </div>
                      ))}
                    </div>
                    </div>
                    }
                  
                </Col>
            </Row>
        </Container>
      </section>   
      
      <Modal open={showMap} handleClose={() => setShowMap(false)}>
        <GoogleMap properties={[propertyDetails]} />
      </Modal>

      <FooterComponent />
    </>
  );
};

// export default EstateDetails;
const mapStateToProps = (state) => {
  //   console.log(`state => `, state);
  return { searchState: state.searchState };
};

const mapDispatchToProps = (dispatch) => ({
  setShowings: (data) => {
    dispatch(setShowings(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EstateDetails);
