import HTTP from "../common/ajax";

class UserModel {  
  async getWebsiteUsers() {
    try {
      const res = await HTTP.get("public/users/get");
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async fetchUserDetails(userSlug) {
    try {
      const res = await HTTP.get(`public/users/indi/${userSlug}`);     
      if(res.data.user){
        var user=res.data.user;
        var VM_APP_AGENT_NAME =user.contact_name;
        var VM_APP_EMAIL =user.email;                
        var VM_APP_AGENT_TITLE =user.title;                
        var VM_APP_PHONE_NUMBER =user.phone_number;                
        var VM_APP_FB_LINK =user.fb_link;                
        var VM_APP_TWITTER_LINK =user.twitter_link;                
        var VM_APP_YOUTUBE_LINK =user.youtube_link;                
        var VM_APP_INSTA_LINK =user.insta_link;                
        var agent_id =user.id; 
        
        window.sessionStorage.setItem("VM_AGENT_SET", 1);
        window.sessionStorage.setItem("VM_APP_AGENT_NAME", VM_APP_AGENT_NAME);
        window.sessionStorage.setItem("VM_APP_EMAIL", VM_APP_EMAIL);
        window.sessionStorage.setItem("VM_APP_AGENT_TITLE", VM_APP_AGENT_TITLE);
        window.sessionStorage.setItem("VM_APP_PHONE_NUMBER", VM_APP_PHONE_NUMBER);
        window.sessionStorage.setItem("VM_APP_FB_LINK", VM_APP_FB_LINK);
        window.sessionStorage.setItem("VM_APP_TWITTER_LINK", VM_APP_TWITTER_LINK);
        window.sessionStorage.setItem("VM_APP_YOUTUBE_LINK", VM_APP_YOUTUBE_LINK);
        window.sessionStorage.setItem("VM_APP_INSTA_LINK", VM_APP_INSTA_LINK);
        window.sessionStorage.setItem("VM_AGENT_ID", agent_id);

      }   
      return true;
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

}

export default new UserModel();
