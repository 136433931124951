import React, { useState, useEffect } from "react";
import { Container, Row, Col,Image } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get, find } from "lodash";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import axios from "../../common/ajax";
import { useParams } from "react-router-dom";
import LoadingButton from "../../componets/LoadingButton";
import { Link } from "react-router-dom";

import WorthModel from "../../Models/WorthModel";
import Spinner from "../../componets/Spinner";

const HomeWorthForm = (props) => {
    const formName=props.formName;
    const pageName=props.pageName;
    const showUnit=props.showUnit;
    const [loading, setLoading] = useState(0);

    async function handleWorthForm(payload) {
        try {
          await WorthModel.create({
            ...payload,
          });
    
          Swal.fire(`Done`, `Submitted successfully`, "success");
          setLoading(false);
    
          setTimeout(function () {
            window.location='/';
        }, 2000); 
        } catch (error) {
          console.log("Error", error);
          Swal.fire(`Error`, `Something went wrong.`, "error");
          setLoading(false);
        }
      }
    
    const formik = useFormik({
        initialValues: {
            address: get("", "address", ""),
            unit: get("", "unit", ""),
            full_name: get("", "full_name", ""),
            email: get("", "email", ""),
            web_url: get("", "web_url",pageName),
            lead_type: get("", "lead_type",formName),
        },
        validationSchema: Yup.object({
            full_name: Yup.string().min(3, 'Name must be between 3 and 50 characters').max(50, 'Name must be between 3 and 50 characters').required("Please enter your name"),
            address: Yup.string().min(3, 'Address must be between 3 and 50 characters').max(50, 'Address must be between 3 and 50 characters').required("Please enter your address"),
            email: Yup.string().email('Invalid email').required('Please enter your email'),
        }),
        onSubmit: (values) => {
        setLoading(true);
            handleWorthForm(values);
        },
    });

    return (
        <>
            <div className="pt-4">                                
                {showUnit == 1 ? (
                    <div className="row pb-2 pb-md-4">
                        <div className="col-md-6 col-xs-12 pb-2 pb-md-0">
                            <input type="text" full_name="address" id="address" className="form-control rounded-0 py-3" placeholder="Address, City or Postal Code *" value={formik.values.address} onChange={(e) =>formik.setFieldValue("address", e.target.value)} onBlur={() => formik.setFieldTouched("address")}/>

                            {Boolean(formik.errors.address) &&
                            formik.touched.address ? (
                                <small
                                id="address"
                                className={
                                    "form-text " + Boolean(formik.errors.address)
                                    ? "text-danger"
                                    : "text-muted"
                                }
                                >
                                {formik.errors.address}
                                </small>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <input type="text" full_name="unit" id="unit" className="form-control rounded-0 py-3" placeholder="Unit if exists" value={formik.values.unit} onChange={(e) =>formik.setFieldValue("unit", e.target.value)} onBlur={() => formik.setFieldTouched("unit")}/> 

                            {Boolean(formik.errors.unit) &&
                            formik.touched.unit ? (
                                <small
                                id="unit"
                                className={
                                    "form-text " + Boolean(formik.errors.unit)
                                    ? "text-danger"
                                    : "text-muted"
                                }
                                >
                                {formik.errors.unit}
                                </small>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                ): (
                    <div className="row pb-4">
                        <div className="col-md-12 col-xs-12">
                            <input type="text" full_name="address" id="address" className="form-control rounded-0 py-3" placeholder="Address, City or Postal Code *" value={formik.values.address} onChange={(e) =>formik.setFieldValue("address", e.target.value)} onBlur={() => formik.setFieldTouched("address")}/>

                            {Boolean(formik.errors.address) &&
                            formik.touched.address ? (
                                <small
                                id="address"
                                className={
                                    "form-text " + Boolean(formik.errors.address)
                                    ? "text-danger"
                                    : "text-muted"
                                }
                                >
                                {formik.errors.address}
                                </small>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                )}                  
                <div className="row pb-4">
                    <div className="col-md-6 col-xs-12 pb-2 pb-md-0">
                        <input type="text" full_name="full_name" id="full_name" className="form-control rounded-0 py-3" placeholder="Name *" value={formik.values.full_name} onChange={(e) =>formik.setFieldValue("full_name", e.target.value)} onBlur={() => formik.setFieldTouched("full_name")}/>
                        {Boolean(formik.errors.full_name) &&
                        formik.touched.full_name ? (
                            <small
                            id="full_name"
                            className={
                                "form-text " + Boolean(formik.errors.full_name)
                                ? "text-danger"
                                : "text-muted"
                            }
                            >
                            {formik.errors.full_name}
                            </small>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <input type="email" full_name="email" id="email" className="form-control rounded-0 py-3" placeholder="Email *" value={formik.values.email} onChange={(e) =>formik.setFieldValue("email", e.target.value)} onBlur={() => formik.setFieldTouched("email")}/> 

                        {Boolean(formik.errors.email) &&
                        formik.touched.email ? (
                            <small
                            id="email"
                            className={
                                "form-text " + Boolean(formik.errors.email)
                                ? "text-danger"
                                : "text-muted"
                            }
                            >
                            {formik.errors.email}
                            </small>
                        ) : (
                            ""
                        )} 
                    </div>                
                </div>
                <div className="d-flex justify-content-center justify-content-md-center">
                    <LoadingButton
                        type="submit"
                        className=""
                        title={props.btnName}
                        style="btn_dark_black"
                        loading={loading}
                        handleClick={() => formik.submitForm()}
                    />
                </div>
            </div>
        </>
    );
};

export default HomeWorthForm;